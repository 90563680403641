.nobreak {
  white-space: nowrap;
}
.nohyphens {
  -webkit-hyphens: none;
      -ms-hyphens: none;
          hyphens: none;
}
.hideme {
  display: none !important;
}
::-moz-selection {
  background: #E2002A;
  color: #fff;
}
::selection {
  background: #E2002A;
  color: #fff;
}
* {
  -webkit-tap-highlight-color: rgba(226, 0, 42, 0.1);
  box-sizing: border-box;
}
main {
  display: block;
}
html {
  -webkit-text-size-adjust: 100%;
          text-size-adjust: 100%;
}
body {
  letter-spacing: calc((0.875rem + 0.0625
                                 * (100vw - 17.5rem)
                                 / (93.75 - 17.5))
                                 / 1000 * 0);
  font-size: 0.875rem;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  background: #fff;
  color: #707173;
  font-family: "Rubik", sans-serif;
  -webkit-font-feature-settings: "liga", "kern";
          font-feature-settings: "liga", "kern";
  -webkit-font-kerning: normal;
          font-kerning: normal;
  font-smoothing: antialiased;
  font-weight: 400;
  hyphenate-limit-chars: 6 3 2;
  hyphenate-limit-lines: 2;
  -webkit-hyphens: auto;
      -ms-hyphens: auto;
          hyphens: auto;
  line-height: 1.8;
  margin: 0 auto;
  max-width: 120rem;
  overflow-x: hidden;
  text-rendering: geometricPrecision;
}
@media (min-width: 17.5rem) {
  body {
    font-size: calc(0.875rem + 0.0625
                            * (100vw - 17.5rem)
                            / (93.75 - 17.5));
  }
}
@media (min-width: 93.75rem) {
  body {
    font-size: 0.9375rem;
  }
}
body.no_scroll {
  overflow: hidden;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  -webkit-hyphens: none;
      -ms-hyphens: none;
          hyphens: none;
  word-wrap: break-word;
}
h1 {
  letter-spacing: calc((1.25rem + 1.75
                                 * (100vw - 17.5rem)
                                 / (93.75 - 17.5))
                                 / 1000 * 0);
  font-size: 1.25rem;
  color: #E2002A;
  font-family: "Rubik", sans-serif;
  font-weight: 700;
  line-height: 1.35;
  margin: 0 0 0.9em;
  text-transform: uppercase;
}
@media (min-width: 17.5rem) {
  h1 {
    font-size: calc(1.25rem + 1.75
                            * (100vw - 17.5rem)
                            / (93.75 - 17.5));
  }
}
@media (min-width: 93.75rem) {
  h1 {
    font-size: 3rem;
  }
}
h2 {
  letter-spacing: calc((1.125rem + 1.5
                                 * (100vw - 17.5rem)
                                 / (93.75 - 17.5))
                                 / 1000 * 0);
  font-size: 1.125rem;
  color: #E2002A;
  font-family: "Rubik", sans-serif;
  font-weight: 700;
  line-height: 1.35;
  margin: 0 0 0.9em;
  text-transform: uppercase;
}
@media (min-width: 17.5rem) {
  h2 {
    font-size: calc(1.125rem + 1.5
                            * (100vw - 17.5rem)
                            / (93.75 - 17.5));
  }
}
@media (min-width: 93.75rem) {
  h2 {
    font-size: 2.625rem;
  }
}
h3 {
  letter-spacing: calc((1rem + 0.1875
                                 * (100vw - 17.5rem)
                                 / (93.75 - 17.5))
                                 / 1000 * 0);
  font-size: 1rem;
  color: #202020;
  font-family: "Rubik", sans-serif;
  font-weight: 700;
  line-height: 1.35;
  margin: 0 0 0.9em;
  padding-top: 0.9em;
}
@media (min-width: 17.5rem) {
  h3 {
    font-size: calc(1rem + 0.1875
                            * (100vw - 17.5rem)
                            / (93.75 - 17.5));
  }
}
@media (min-width: 93.75rem) {
  h3 {
    font-size: 1.1875rem;
  }
}
h4 {
  letter-spacing: calc((0.75rem + 0.125
                                 * (100vw - 17.5rem)
                                 / (93.75 - 17.5))
                                 / 1000 * 0);
  font-size: 0.75rem;
  color: #202020;
  font-family: "Rubik", sans-serif;
  font-weight: 700;
  line-height: 1.35;
  margin: 0 0 0.9em;
  text-transform: uppercase;
}
@media (min-width: 17.5rem) {
  h4 {
    font-size: calc(0.75rem + 0.125
                            * (100vw - 17.5rem)
                            / (93.75 - 17.5));
  }
}
@media (min-width: 93.75rem) {
  h4 {
    font-size: 0.875rem;
  }
}
h5 {
  letter-spacing: calc((0.875rem + 0.125
                                 * (100vw - 17.5rem)
                                 / (93.75 - 17.5))
                                 / 1000 * 0);
  font-size: 0.875rem;
  color: #202020;
  font-family: "Rubik", sans-serif;
  font-weight: 700;
  line-height: 1.35;
  margin: 0;
}
@media (min-width: 17.5rem) {
  h5 {
    font-size: calc(0.875rem + 0.125
                            * (100vw - 17.5rem)
                            / (93.75 - 17.5));
  }
}
@media (min-width: 93.75rem) {
  h5 {
    font-size: 1rem;
  }
}
h6 {
  height: 0;
  margin: 0;
  overflow: hidden;
  width: 0;
}
p {
  margin: 0 0 0.9em;
}
a {
  color: #E2002A;
  -webkit-transition: color 300ms;
  transition: color 300ms;
}
a:active,
a.active {
  color: #707173;
  outline: 0;
}
a:focus {
  outline: 0.1875rem solid rgba(226, 0, 42, 0.8);
  outline-offset: 0.0625rem;
  text-decoration: none;
}
a:focus[data-focus-method="mouse"],
a:focus[data-focus-method="touch"] {
  outline: 0;
}
a:hover {
  text-decoration: none;
}
a[tabindex="-1"]:focus {
  outline: 0;
}
a[tabindex="-1"][id] {
  position: relative;
  top: -2.5rem;
}
a[tabindex="-1"][id]:after {
  content: "";
  display: none;
}
a.touch_link {
  color: #707173;
  display: block;
  text-decoration: none;
}
a.touch_link .title {
  color: #E2002A;
  display: inline-block;
  margin-bottom: 0.45em;
  outline-offset: 0;
  padding: 0;
  text-decoration: underline;
  -webkit-transition: color 300ms;
  transition: color 300ms;
}
a.touch_link .title.active {
  color: #707173;
}
a.touch_link:focus {
  outline: 0;
}
a.touch_link:focus .title {
  outline: 0.1875rem solid rgba(226, 0, 42, 0.8);
  outline-offset: 0.0625rem;
  text-decoration: none;
}
a.touch_link:focus[data-focus-method="mouse"] .title,
a.touch_link:focus[data-focus-method="touch"] .title {
  outline: 0;
}
a.touch_link:hover .title {
  text-decoration: none;
}
.highlighted {
  -webkit-animation: highlighted_anchor 900ms;
          animation: highlighted_anchor 900ms;
}
strong {
  font-weight: 700;
}
em {
  font-style: normal;
}
blockquote {
  margin: 0 0 0.9em 1ch;
  text-indent: -1ch;
}
blockquote:before {
  content: "\201E";
}
blockquote:after {
  content: "\201C";
}
small {
  font-size: 85%;
}
sup {
  font-size: 85%;
  line-height: 1;
  position: relative;
  top: -0.3125rem;
}
sub {
  bottom: -0.125rem;
  font-size: 85%;
  line-height: 1;
  position: relative;
}
.table {
  -webkit-overflow-scrolling: touch;
  margin-bottom: 1.8em;
  overflow-y: auto;
}
* + .table {
  margin-top: 1.8em;
}
table {
  border-bottom: 0.0625rem solid #bdbebf;
  border-collapse: collapse;
  border-spacing: 0;
  margin: 0;
  width: 100%;
}
th {
  font-weight: 700;
  text-align: left;
}
td,
th {
  border-top: 0.0625rem solid #bdbebf;
  padding: 0.6em;
  -webkit-transition: background 300ms;
  transition: background 300ms;
  vertical-align: top;
}
tr:hover td,
tr:hover th {
  background: #d7d7d8;
}
ol {
  counter-reset: list;
  list-style: none;
  margin: 0 0 0.9em;
  padding: 0;
}
ol > li {
  padding-left: 1.8em;
  position: relative;
}
ol > li:before {
  color: #E2002A;
  content: counter(list) ".";
  counter-increment: list;
  font-weight: 700;
  left: 0;
  line-height: 1.8;
  position: absolute;
  text-align: right;
  width: 1.35em;
}
ol ol,
ol ul {
  margin: 0;
}
ul {
  list-style: url("../images/list_style_image_rectangle.svg");
  margin: 0 0 0.9em;
  padding-left: 1.35em;
}
ul > li {
  padding-left: 0.45em;
}
ul ol,
ul ul {
  margin: 0;
}
.defined_list_entry dl {
  border-bottom: 0.0625rem solid #bdbebf;
  margin: 0.9em 0;
}
@media (min-width: 35rem) {
  .defined_list_entry dl {
    display: -webkit-box;
    display: flex;
    flex-wrap: wrap;
  }
}
.defined_list_entry dt {
  border-top: 0.0625rem solid #bdbebf;
  font-weight: 700;
  padding: 0.9em 0;
}
@media (min-width: 35rem) {
  .defined_list_entry dt {
    -webkit-box-flex: 0;
            flex: 0 0 40%;
    max-width: 40%;
    padding: 0.9em 0.9em 0.9em 0;
  }
}
.defined_list_entry dd {
  margin: 0;
}
@media (min-width: 35rem) {
  .defined_list_entry dd {
    border-top: 0.0625rem solid #bdbebf;
    -webkit-box-flex: 0;
            flex: 0 0 60%;
    max-width: 60%;
    padding: 0.9em 0.6em 0 0;
  }
}
nav {
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
nav ol {
  margin: 0;
}
nav ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
nav li {
  margin: 0;
  padding: 0;
}
nav li:before {
  display: none;
}
figure {
  margin: 0 0 1.8em;
}
figure img {
  display: block;
}
figure.center {
  margin-left: auto;
  margin-right: auto;
}
figure.floatleft {
  float: left;
  margin-right: 1.8em;
}
figure.floatright {
  float: right;
  margin-left: 1.8em;
}
figcaption {
  font-size: 85%;
  margin-top: 0.45em;
  text-align: left;
}
.img_container {
  overflow: hidden;
}
.img_container img,
.img_container svg {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
.img_resize {
  height: 0;
  position: relative;
  width: 100%;
}
img {
  border: 0;
  display: inline-block;
  height: auto;
  max-width: 100%;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
svg {
  height: 100%;
  -webkit-transform: scale(1);
          transform: scale(1);
  width: 100%;
}
svg a:focus {
  outline: 0;
}
input,
select,
textarea,
button {
  font-family: inherit;
  font-size: 1rem;
  font-weight: 400;
}
input,
select,
textarea {
  font-size: 0.875rem;
}
label {
  cursor: pointer;
}
input[type="number"] {
  -moz-appearance: textfield;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}
input[type="time"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-inner-spin-button,
input[type="time"]::-webkit-outer-spin-button,
input[type="date"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}
input:-webkit-autofill {
  -webkit-text-fill-color: #707173;
  box-shadow: 0 !important;
}
input::-ms-clear {
  display: none;
  height: 0;
  width: 0;
}
::-webkit-input-placeholder {
  color: #b0b1b2;
  -webkit-user-select: none;
          user-select: none;
}
:-ms-input-placeholder {
  color: #b0b1b2;
  -ms-user-select: none;
      user-select: none;
}
::placeholder {
  color: #b0b1b2;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.required_asterisk {
  color: #E2002A;
}
.required_description {
  font-size: 85%;
}
.error_warning {
  background: #E2002A;
  color: #fff;
  display: none;
  font-weight: 700;
  margin-right: 0.45em;
  padding: 0 0.45em;
  text-align: center;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.error_warning.show {
  display: inline-block;
}
fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}
.legend {
  letter-spacing: calc((1.1875rem + 0.25
                                 * (100vw - 17.5rem)
                                 / (93.75 - 17.5))
                                 / 1000 * 0);
  font-size: 1.1875rem;
  color: #707173;
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  line-height: 1.35;
  margin: 0 0 0.9em;
  padding: 0.9em 0 0;
  width: 100%;
}
@media (min-width: 17.5rem) {
  .legend {
    font-size: calc(1.1875rem + 0.25
                            * (100vw - 17.5rem)
                            / (93.75 - 17.5));
  }
}
@media (min-width: 93.75rem) {
  .legend {
    font-size: 1.4375rem;
  }
}
.legend .label {
  margin-bottom: 0;
}
.label {
  display: inline-block;
  margin-bottom: 0.45em;
}
.input,
.textarea {
  -moz-appearance: none;
  -webkit-appearance: none;
  background: #fff;
  border: 0.0625rem solid #bdbebf;
  border-radius: 0.25rem;
  box-shadow: 0;
  color: #707173;
  margin: 0 0 1.2em;
  outline: 0;
  width: 100%;
}
.input[aria-invalid="true"],
.textarea[aria-invalid="true"] {
  border-color: #E2002A;
}
.input[aria-invalid="true"]:focus,
.textarea[aria-invalid="true"]:focus {
  border-color: #202020;
  box-shadow: 0;
  color: #707173;
}
.input:focus,
.textarea:focus {
  border-color: #202020;
  box-shadow: 0;
  color: #707173;
}
.input {
  height: 2.75rem;
  padding: 0 0.9em;
  -webkit-transition: border 300ms;
  transition: border 300ms;
}
.textarea {
  line-height: 1.8;
  overflow: hidden;
  padding: 0.9em;
  resize: none;
  -webkit-transition: border 300ms, height 300ms;
  transition: border 300ms, height 300ms;
}
.control {
  -webkit-box-align: start;
          align-items: flex-start;
  display: -webkit-box;
  display: flex;
  margin: 0.45em 0.9em 0.9em 0;
}
.control.checkbox input:checked ~ .indicator {
  background-color: #202020;
  border-color: #202020;
}
.control.checkbox input:checked ~ .indicator .icon {
  opacity: 1;
  visibility: visible;
}
.control input {
  border: 0;
  cursor: pointer;
  height: 1.5rem;
  margin: 0;
  opacity: 0;
  padding: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 1.5rem;
  z-index: 10;
}
.control input[aria-invalid="true"] ~ .indicator {
  border-color: #E2002A;
}
.control input[aria-invalid="true"]:focus ~ .indicator {
  border-color: #202020;
  box-shadow: 0;
  color: #707173;
}
.control input:focus ~ .indicator {
  border-color: #202020;
  box-shadow: 0;
  color: #707173;
}
.control .indicator_wrapper {
  -webkit-box-align: center;
          align-items: center;
  display: -webkit-box;
  display: flex;
  flex-shrink: 0;
  margin-right: 0.9em;
  position: relative;
}
.control .indicator {
  background: #fff;
  border: 0.0625rem solid #bdbebf;
  border-radius: 0.25rem;
  height: 1.375rem;
  position: relative;
  -webkit-transition: border 300ms;
  transition: border 300ms;
  width: 1.375rem;
}
.control .indicator .icon {
  fill: #fff;
  height: 1.3125rem;
  opacity: 0;
  position: absolute;
  -webkit-transition: opacity 300ms;
  transition: opacity 300ms;
  visibility: hidden;
  width: 1.3125rem;
}
.select_wrapper {
  display: block;
}
.select {
  background: #fff;
  border: 0.0625rem solid #bdbebf;
  border-radius: 0.25rem;
  box-shadow: 0;
  color: #707173;
  cursor: pointer;
  display: block;
  margin: 0 0 1.2em;
  padding: 0 0.9em;
  position: relative;
  -webkit-transition: border 300ms;
  transition: border 300ms;
  width: 100%;
}
.select:before {
  background: url("../images/arrow.svg") no-repeat;
  background-color: #202020;
  background-position: center center;
  background-repeat: none;
  background-size: 35%;
  border-bottom-right-radius: 0.25rem;
  border-top-color: #707173;
  border-top-right-radius: 0.25rem;
  content: "";
  display: inline-block;
  height: 2.75rem;
  pointer-events: none;
  position: absolute;
  right: -0.0625rem;
  top: -0.0625rem;
  width: 2.75rem;
}
.select.error {
  border-color: #E2002A;
}
.select.error.focus {
  border-color: #202020;
  box-shadow: 0;
  color: #707173;
}
.select.focus {
  border-color: #202020;
  box-shadow: 0;
  color: #707173;
}
.select select {
  -moz-appearance: none;
  -webkit-appearance: none;
  background: transparent;
  border: 0;
  color: #707173;
  cursor: pointer;
  height: calc(2.75rem - (0.0625rem * 2));
  margin: 0;
  outline: 0;
  overflow: hidden;
  padding: 0;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
}
.select select::-ms-expand {
  display: none;
}
.select select:focus:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #707173;
}
.select select:focus::-ms-value {
  background: none;
  color: #707173;
}
@-webkit-keyframes preloader_rotate {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(-360deg);
            transform: rotate(-360deg);
  }
}
@keyframes preloader_rotate {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(-360deg);
            transform: rotate(-360deg);
  }
}
@-webkit-keyframes loader {
  to {
    -webkit-transform: rotate(360deg) translatez(0);
            transform: rotate(360deg) translatez(0);
  }
}
@keyframes loader {
  to {
    -webkit-transform: rotate(360deg) translatez(0);
            transform: rotate(360deg) translatez(0);
  }
}
@-webkit-keyframes appear {
  0% {
    -webkit-transform: scale(0) rotate(0deg);
            transform: scale(0) rotate(0deg);
  }
  60% {
    -webkit-transform: scale(1.2) rotate(20deg);
            transform: scale(1.2) rotate(20deg);
  }
  100% {
    -webkit-transform: scale(1) rotate(20deg);
            transform: scale(1) rotate(20deg);
  }
}
@keyframes appear {
  0% {
    -webkit-transform: scale(0) rotate(0deg);
            transform: scale(0) rotate(0deg);
  }
  60% {
    -webkit-transform: scale(1.2) rotate(20deg);
            transform: scale(1.2) rotate(20deg);
  }
  100% {
    -webkit-transform: scale(1) rotate(20deg);
            transform: scale(1) rotate(20deg);
  }
}
@-webkit-keyframes highlighted_anchor {
  0% {
    background: transparent;
    box-shadow: none;
  }
  70% {
    background: rgba(226, 0, 42, 0.1);
    box-shadow: 0 0 0 0.625rem rgba(226, 0, 42, 0.1);
  }
  100% {
    background: transparent;
    box-shadow: none;
  }
}
@keyframes highlighted_anchor {
  0% {
    background: transparent;
    box-shadow: none;
  }
  70% {
    background: rgba(226, 0, 42, 0.1);
    box-shadow: 0 0 0 0.625rem rgba(226, 0, 42, 0.1);
  }
  100% {
    background: transparent;
    box-shadow: none;
  }
}
@-webkit-keyframes image_gallery_spinner {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
@keyframes image_gallery_spinner {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
@-webkit-keyframes image_gallery_bounce_from_right {
  0% {
    margin-left: 0;
  }
  50% {
    margin-left: -1.875rem;
  }
  100% {
    margin-left: 0;
  }
}
@keyframes image_gallery_bounce_from_right {
  0% {
    margin-left: 0;
  }
  50% {
    margin-left: -1.875rem;
  }
  100% {
    margin-left: 0;
  }
}
@-webkit-keyframes image_gallery_bounce_from_left {
  0% {
    margin-left: 0;
  }
  50% {
    margin-left: 1.875rem;
  }
  100% {
    margin-left: 0;
  }
}
@keyframes image_gallery_bounce_from_left {
  0% {
    margin-left: 0;
  }
  50% {
    margin-left: 1.875rem;
  }
  100% {
    margin-left: 0;
  }
}
@media screen {
  .visible_for_screen_reader {
    border: 0;
    clip: rect(0.0625rem, 0.0625rem, 0.0625rem, 0.0625rem);
    height: 0.0625rem;
    left: -624.9375rem;
    overflow: hidden;
    padding: 0;
    position: absolute;
    top: -624.9375rem;
    width: 0.0625rem;
  }
  abbr {
    text-decoration: none;
  }
  [role="tablist"] {
    margin-bottom: 1.8em;
  }
  * + [role="tablist"] {
    margin-top: 1.8em;
  }
  [role="tab"] {
    -webkit-box-align: center;
            align-items: center;
    border-top: 0.0625rem solid #bdbebf;
    cursor: pointer;
    display: -webkit-box;
    display: flex;
    font-size: 100%;
    margin: 0 0 0.45em;
    min-width: 100%;
    padding: 0.6em 0;
    -webkit-transition: all 300ms linear;
    transition: all 300ms linear;
  }
  [role="tab"] .title {
    color: #202020;
    flex-basis: 100%;
    margin-right: 0.9em;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  [role="tab"] .icon {
    display: block;
    fill: #E2002A;
    height: 1.5rem;
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
    width: 1.5rem;
  }
  [role="tab"][aria-expanded="true"] {
    border-color: #E2002A;
  }
  [role="tab"][aria-expanded="true"] .icon {
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg);
  }
  [role="tab"]:focus,
  [role="tab"]:hover,
  [role="tab"].active {
    border-color: #E2002A;
    outline: 0;
  }
  [role="tab"]:focus .title,
  [role="tab"]:hover .title,
  [role="tab"].active .title {
    color: #E2002A;
  }
  [role="tabpanel"] {
    height: auto;
    margin: 0;
    overflow: hidden;
  }
  [role="tabpanel"][aria-hidden="false"] {
    margin: 1.8em 0;
  }
  .csstransitions [role="tabpanel"].animated {
    -webkit-transition: margin 600ms, max-height 600ms;
    transition: margin 600ms, max-height 600ms;
  }
  .address .company_name {
    margin-bottom: 0;
  }
  .address .addition {
    color: #202020;
    display: block;
    font-size: 50%;
  }
  .contact_wrapper {
    display: -webkit-box;
    display: flex;
    flex-wrap: wrap;
  }
  .contact_wrapper p {
    margin-right: 2.7em;
  }
  .contact_wrapper .address > span {
    display: block;
  }
  .contact {
    display: -webkit-box;
    display: flex;
  }
  .contact strong {
    min-width: 5rem;
  }
  .address_vcard_button {
    margin: 0.45em 0 1.8em !important;
  }
  .address_call_button {
    margin: 0.9em 0 0;
  }
  .aside {
    margin-bottom: 7.2em;
    margin-top: 0;
  }
  .steuernews_newsletter_subscribe h2 {
    font-size: 90%;
  }
  .atikon_logo {
    height: 100%;
  }
  .breadcrumbs {
    display: none;
    flex-wrap: wrap;
    margin-bottom: 0.9em;
  }
  .breadcrumbs p {
    margin: 0 0.45em 0 0;
  }
  .breadcrumbs ol {
    display: -webkit-box;
    display: flex;
    flex-wrap: wrap;
  }
  .breadcrumbs .separator {
    margin: 0 0.45em;
  }
  .button {
    border-radius: 0.25rem;
    font-size: 1rem;
    padding: 0 1.8em;
    background: #E2002A;
    border: 0.125rem solid #E2002A;
    color: #fff;
    cursor: pointer;
    display: -webkit-inline-box;
    display: inline-flex;
    -webkit-box-pack: center;
            justify-content: center;
    line-height: 1.8;
    margin-top: 1.8em;
    text-decoration: none;
    text-transform: uppercase;
    -webkit-transition: background 300ms, border 300ms, color 300ms;
    transition: background 300ms, border 300ms, color 300ms;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
  }
  .button.black {
    background: #202020;
    border-color: #202020;
  }
  .button.black:hover {
    background: #E2002A;
    border-color: #202020;
  }
  .button.light {
    background: transparent;
    border-color: #fff;
  }
  .button.light:hover {
    background: #fff;
    border-color: #fff;
    color: #E2002A;
  }
  .button > span {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
            justify-content: center;
    min-height: 2.75rem;
  }
  .button .icon {
    fill: #fff;
    flex-shrink: 0;
    height: 1.5rem;
    -webkit-transition: fill 300ms;
    transition: fill 300ms;
    width: 1.5rem;
  }
  .button .text {
    font-size: 90%;
    font-weight: 700;
    padding: 0.45em 0;
    text-align: center;
  }
  .button .icon + .text {
    padding-left: 0.6em;
  }
  .button:focus {
    box-shadow: 0 0 0 0.1875rem rgba(226, 0, 42, 0.2);
    outline: 0;
    background: #ff1641;
    border-color: #ff1641;
    color: #fff;
  }
  .button:focus .icon {
    fill: #fff;
  }
  .button:hover {
    background: #202020;
    border-color: #202020;
    color: #fff;
  }
  .button:hover .icon {
    fill: #fff;
  }
  .button:active,
  .button.active {
    box-shadow: 0 0 0.1875rem #E2002A inset;
    background: #E2002A;
    border: 0.125rem solid #E2002A;
    color: #fff;
    cursor: pointer;
    display: -webkit-inline-box;
    display: inline-flex;
    -webkit-box-pack: center;
            justify-content: center;
    line-height: 1.8;
    margin-top: 1.8em;
    text-decoration: none;
    text-transform: uppercase;
    -webkit-transition: background 300ms, border 300ms, color 300ms;
    transition: background 300ms, border 300ms, color 300ms;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
  }
  .button:active.black,
  .button.active.black {
    background: #202020;
    border-color: #202020;
  }
  .button:active.black:hover,
  .button.active.black:hover {
    background: #E2002A;
    border-color: #202020;
  }
  .button:active.light,
  .button.active.light {
    background: transparent;
    border-color: #fff;
  }
  .button:active.light:hover,
  .button.active.light:hover {
    background: #fff;
    border-color: #fff;
    color: #E2002A;
  }
  .button:active > span,
  .button.active > span {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
            justify-content: center;
    min-height: 2.75rem;
  }
  .button:active .icon,
  .button.active .icon {
    fill: #fff;
    flex-shrink: 0;
    height: 1.5rem;
    -webkit-transition: fill 300ms;
    transition: fill 300ms;
    width: 1.5rem;
  }
  .button:active .text,
  .button.active .text {
    font-size: 90%;
    font-weight: 700;
    padding: 0.45em 0;
    text-align: center;
  }
  .button:active .icon + .text,
  .button.active .icon + .text {
    padding-left: 0.6em;
  }
  .bypass_blocks {
    left: 0;
    list-style: none;
    margin: 0;
    padding: 0;
    position: absolute;
    top: 0;
    z-index: 6000;
  }
  .bypass_blocks li {
    display: block;
    width: 17.5rem;
  }
  .bypass_blocks a {
    background: #fff;
    color: #E2002A;
    display: inline-block;
    font-size: 85%;
    height: 0.0625rem;
    left: -624.9375rem;
    overflow: hidden;
    padding: 0.45em;
    position: absolute;
    text-decoration: underline;
    top: -624.9375rem;
    width: 0.0625rem;
  }
  .bypass_blocks a:focus {
    color: #E2002A;
    height: auto;
    left: 0.45em;
    top: 0.45em;
    width: auto;
  }
  .steuernews_call_to_action {
    background: #d7d7d8;
    border-radius: 0.25rem;
    display: inline-block;
    margin: 0 0 1.8em;
    padding: 0.9em;
  }
  .steuernews_call_to_action p {
    margin: 0;
  }
  .company_logo {
    max-width: 6.25rem;
    display: block;
    max-height: 7.5rem;
    -webkit-transition: all 300ms;
    transition: all 300ms;
  }
  .cookie_banner {
    -webkit-box-align: center;
            align-items: center;
    background: #d7d7d8;
    border-top: 0.125rem solid #bdbebf;
    bottom: 0;
    display: -webkit-box;
    display: flex;
    font-size: 85%;
    left: 0;
    position: fixed;
    right: 0;
    z-index: 9000;
  }
  .cookie_banner p {
    flex-basis: 100%;
    margin: 0;
    padding: 1.2em 0.9em 1.2em 1.8em;
  }
  .cookie_banner .close {
    cursor: pointer;
    flex-shrink: 0;
    margin: 0.9em;
    padding: 0 0.9em;
  }
  .cookie_banner .close > span {
    min-height: 2.75rem;
  }
  .cookie_banner .close .text {
    padding: 0 0.36em;
  }
  .dialog_wrapper {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
    height: 100%;
    left: 0;
    overflow: auto;
    padding: 1.8em 0;
    position: fixed;
    top: 0;
    visibility: hidden;
    width: 100%;
    z-index: 7100;
  }
  .dialog_wrapper.show {
    visibility: visible;
  }
  .dialog_overlay {
    background: rgba(32, 32, 32, 0.8);
    cursor: pointer;
    height: 100%;
    left: 0;
    opacity: 0;
    position: fixed;
    top: 0;
    -webkit-transition: all 300ms;
    transition: all 300ms;
    visibility: hidden;
    width: 100%;
    z-index: 0;
  }
  .dialog_overlay.show {
    opacity: 1;
    visibility: visible;
  }
  [role="dialog"] {
    background: #fff;
    border-radius: 0.25rem;
    box-shadow: rgba(112, 113, 115, 0.3) 0 0.0625rem 0.375rem, rgba(112, 113, 115, 0.2) 0 0.0625rem 0.25rem;
    margin: -6.25rem auto 0;
    max-width: 61.875rem;
    min-width: 17.5rem;
    opacity: 0;
    outline: 0;
    padding: 1.8em;
    position: relative;
    -webkit-transition: all 300ms;
    transition: all 300ms;
    visibility: hidden;
    width: calc(100% - 1.8em);
    z-index: 10;
  }
  [role="dialog"].show {
    margin-top: 0;
    opacity: 1;
    visibility: visible;
  }
  [role="dialog"] .title {
    padding: 0 2.25em 0 0;
  }
  [role="dialog"] .description {
    padding: 0 0 0.9em 0;
  }
  [role="dialog"] .close {
    display: block;
    fill: #E2002A;
    height: 2.75rem;
    margin-left: auto;
    padding: 0.3em;
    position: absolute;
    right: 0.9em;
    top: 1.2em;
    -webkit-transition: fill 300ms;
    transition: fill 300ms;
    width: 2.75rem;
  }
  [role="dialog"] .close:focus,
  [role="dialog"] .close:hover {
    fill: #707173;
  }
  .download_overview {
    margin-bottom: 0.9em;
  }
  .download_overview .touch_link {
    display: -webkit-box;
    display: flex;
  }
  .download_overview .touch_link .title_wrapper {
    margin-bottom: 0.45em;
    padding-top: 0;
  }
  .download_overview .icon {
    flex-shrink: 0;
    height: 1.5rem;
    margin-right: 0.9em;
    width: 1.5rem;
  }
  .download_overview .icon_doc {
    fill: #2a5699;
  }
  .download_overview .icon_xls {
    fill: #4e892d;
  }
  .download_overview .icon_pdf {
    fill: #e44e4e;
  }
  .download_overview .icon_online {
    fill: #0074ce;
  }
  .dropdown_menu {
    display: none;
  }
  .dropdown_menu > ul {
    display: -webkit-box;
    display: flex;
    justify-content: space-around;
    position: relative;
    width: 100%;
  }
  .dropdown_menu > ul > li {
    -webkit-box-align: center;
            align-items: center;
  }
  .dropdown_menu > ul > li:hover > a,
  .dropdown_menu > ul > li.hover > a {
    color: #E2002A;
  }
  .dropdown_menu > ul > li:hover > a:after,
  .dropdown_menu > ul > li.hover > a:after {
    border-bottom: 0.5rem solid #E2002A;
    border-left: 0.5rem solid transparent;
    border-right: 0.5rem solid transparent;
    bottom: -0.625rem;
    clear: both;
    content: "";
    height: 0;
    left: 44%;
    position: absolute;
    width: 0;
  }
  .dropdown_menu > ul > li:hover ul,
  .dropdown_menu > ul > li.hover ul {
    max-height: 25rem;
    -webkit-transition: all 200ms;
    transition: all 200ms;
    visibility: visible;
  }
  .dropdown_menu > ul > li > a {
    -webkit-box-align: center;
            align-items: center;
    color: #202020;
    display: -webkit-box;
    display: flex;
    font-weight: 700;
    -webkit-hyphens: none;
        -ms-hyphens: none;
            hyphens: none;
    padding: 0.9em;
    position: relative;
    text-decoration: none;
    text-transform: uppercase;
    white-space: nowrap;
  }
  .dropdown_menu > ul > li > a.active {
    color: #E2002A;
  }
  .dropdown_menu > ul > li > a.active:after {
    border-bottom: 0.5rem solid #E2002A;
    border-left: 0.5rem solid transparent;
    border-right: 0.5rem solid transparent;
    bottom: -0.625rem;
    clear: both;
    content: "";
    height: 0;
    left: 44%;
    position: absolute;
    width: 0;
  }
  .dropdown_menu > ul > li ul {
    background: #fff;
    border-radius: 0.25rem;
    border-top: 0.25rem solid #E2002A;
    box-shadow: 0rem 0.125rem 0.3125rem 0rem rgba(0, 0, 0, 0.1);
    max-height: 0;
    overflow: hidden;
    padding: 0.9em 0;
    position: absolute;
    top: 4.14em;
    -webkit-transition: all 200ms;
    transition: all 200ms;
    visibility: hidden;
    z-index: 99999;
  }
  .dropdown_menu > ul > li ul:target {
    visibility: visible;
  }
  .dropdown_menu > ul > li ul a {
    color: #202020;
    display: block;
    -webkit-hyphens: none;
        -ms-hyphens: none;
            hyphens: none;
    line-height: 1.8;
    padding: 0 0.9em;
    text-decoration: none;
    white-space: nowrap;
  }
  .dropdown_menu > ul > li ul a:hover {
    color: #E2002A;
  }
  .dropdown_menu > ul > li ul a:focus {
    outline-offset: -0.125rem;
  }
  .dropdown_menu > ul > li ul a.active {
    color: #E2002A;
  }
  .dropdown_menu > ul > li ul a.with_icon {
    display: -webkit-box;
    display: flex;
    line-height: 4;
  }
  .dropdown_menu > ul > li ul a.with_icon:last-child .icon {
    margin-bottom: 0;
  }
  .dropdown_menu > ul > li ul a.with_icon .icon {
    display: block;
    fill: #707173;
    height: 2.625rem;
    margin: 0.45em 0.9em 0.45em 0;
    width: 2.625rem;
  }
  .footer_pre {
    background: #F3F1EF;
    font-size: 0.875rem;
    line-height: 1.8;
    padding: 7.2em 0;
  }
  .footer_pre h4 {
    color: #707173;
  }
  .footer_pre a {
    color: #707173;
    text-decoration: none;
  }
  .footer_pre a:hover {
    color: #E2002A;
  }
  .footer_socialmedia .social_media_links .facebook {
    -webkit-box-align: center;
            align-items: center;
    color: #707173;
    display: none;
    font-weight: 500;
    -webkit-box-pack: center;
            justify-content: center;
    margin-top: 0.3em;
    text-decoration: none;
  }
  .footer_socialmedia .social_media_links .facebook:hover {
    color: #202020;
    -webkit-transition: color 300ms;
    transition: color 300ms;
  }
  .footer_socialmedia .social_media_links .facebook .icon {
    display: -webkit-inline-box;
    display: inline-flex;
    height: 0.75rem;
    margin-right: 0.45em;
    width: 0.75rem;
  }
  .footer_socialmedia .social_media_links .facebook .text {
    font-size: 70%;
    padding: 0.58064516em 0;
  }
  .footer_post {
    margin: 1.2em 0;
  }
  .footer_links .related_links ul {
    display: -webkit-box;
    display: flex;
  }
  .footer_links .related_links ul li {
    margin-left: 0.9em;
  }
  .footer_links .related_links ul a {
    color: #707173;
    font-size: 80%;
  }
  .form_disclaimer {
    background: #F3F1EF;
    border-radius: 0.25rem;
    color: #707173;
    font-size: 85%;
    margin-bottom: 0.9em;
    padding: 1.2em 1.8em;
  }
  .form_info {
    margin-top: 1.8em;
  }
  .file {
    display: -webkit-box;
    display: flex;
    flex-basis: 65%;
    height: 2.75rem;
    margin: 0 0 1.2em;
    position: relative;
  }
  .file.error .file_name {
    border-color: #E2002A;
  }
  .file .file_name {
    background: #fff;
    border: 0.0625rem solid #bdbebf;
    border-radius: 0.25rem 0 0 0.25rem;
    box-shadow: 0;
    color: #707173;
    -webkit-box-flex: 0;
            flex: 0 1 100%;
    height: 2.75rem;
    line-height: calc(2.75rem - (0.0625rem * 2));
    overflow: hidden;
    padding: 0 0.9em;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 8.125rem;
  }
  .file .file_button {
    border-radius: 0 0.25rem 0.25rem 0;
    -webkit-box-flex: 1;
            flex: 1 0 auto;
    line-height: 1.5;
    margin-top: 0 !important;
    padding: 0 1.8em;
    z-index: 10;
    background: #E2002A;
    border: 0.125rem solid #E2002A;
    color: #fff;
    cursor: pointer;
    display: -webkit-inline-box;
    display: inline-flex;
    -webkit-box-pack: center;
            justify-content: center;
    line-height: 1.8;
    margin-top: 1.8em;
    text-decoration: none;
    text-transform: uppercase;
    -webkit-transition: background 300ms, border 300ms, color 300ms;
    transition: background 300ms, border 300ms, color 300ms;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
  }
  .file .file_button.black {
    background: #202020;
    border-color: #202020;
  }
  .file .file_button.black:hover {
    background: #E2002A;
    border-color: #202020;
  }
  .file .file_button.light {
    background: transparent;
    border-color: #fff;
  }
  .file .file_button.light:hover {
    background: #fff;
    border-color: #fff;
    color: #E2002A;
  }
  .file .file_button > span {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
            justify-content: center;
    min-height: 2.75rem;
  }
  .file .file_button .icon {
    fill: #fff;
    flex-shrink: 0;
    height: 1.5rem;
    -webkit-transition: fill 300ms;
    transition: fill 300ms;
    width: 1.5rem;
  }
  .file .file_button .text {
    font-size: 90%;
    font-weight: 700;
    padding: 0.45em 0;
    text-align: center;
  }
  .file .file_button .icon + .text {
    padding-left: 0.6em;
  }
  .file .file_button.focus {
    box-shadow: 0;
    background: #ff1641;
    border-color: #ff1641;
    color: #fff;
  }
  .file .file_button.focus .icon {
    fill: #fff;
  }
  .file [type="file"] {
    bottom: 0;
    color: transparent;
    cursor: pointer;
    display: block;
    height: 2.75rem;
    left: 0;
    margin: 0;
    opacity: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 20;
  }
  .file [type="file"]:hover ~ .file_button {
    background: #202020;
    border-color: #202020;
    color: #fff;
  }
  .file [type="file"]:hover ~ .file_button .icon {
    fill: #fff;
  }
  .file [type="file"]:active ~ .file_button {
    box-shadow: 0;
    background: #E2002A;
    border: 0.125rem solid #E2002A;
    color: #fff;
    cursor: pointer;
    display: -webkit-inline-box;
    display: inline-flex;
    -webkit-box-pack: center;
            justify-content: center;
    line-height: 1.8;
    margin-top: 1.8em;
    text-decoration: none;
    text-transform: uppercase;
    -webkit-transition: background 300ms, border 300ms, color 300ms;
    transition: background 300ms, border 300ms, color 300ms;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
  }
  .file [type="file"]:active ~ .file_button.black {
    background: #202020;
    border-color: #202020;
  }
  .file [type="file"]:active ~ .file_button.black:hover {
    background: #E2002A;
    border-color: #202020;
  }
  .file [type="file"]:active ~ .file_button.light {
    background: transparent;
    border-color: #fff;
  }
  .file [type="file"]:active ~ .file_button.light:hover {
    background: #fff;
    border-color: #fff;
    color: #E2002A;
  }
  .file [type="file"]:active ~ .file_button > span {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
            justify-content: center;
    min-height: 2.75rem;
  }
  .file [type="file"]:active ~ .file_button .icon {
    fill: #fff;
    flex-shrink: 0;
    height: 1.5rem;
    -webkit-transition: fill 300ms;
    transition: fill 300ms;
    width: 1.5rem;
  }
  .file [type="file"]:active ~ .file_button .text {
    font-size: 90%;
    font-weight: 700;
    padding: 0.45em 0;
    text-align: center;
  }
  .file [type="file"]:active ~ .file_button .icon + .text {
    padding-left: 0.6em;
  }
  .formular .button_wrapper {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
  }
  .formular .button_wrapper .button {
    margin-top: 0;
  }
  .formular .button_wrapper .button:hover,
  .formular .button_wrapper .button:focus {
    border-color: #E2002A;
  }
  .contact_overview_map {
    margin-bottom: 5.4em !important;
  }
  .contact_overview_map h2 {
    display: none;
  }
  .contact_overview_map .map {
    height: 40vh !important;
  }
  .google_maps {
    margin: 0 0 0.9em;
  }
  .google_maps .map {
    background: #d7d7d8;
    height: 50vh;
    min-height: 25rem;
  }
  .google_maps .map img {
    max-width: none;
  }
  .google_maps .map_print {
    display: none;
  }
  .google_maps .travel_mode {
    display: -webkit-box;
    display: flex;
    margin: 0 0 0.9em;
  }
  .google_maps .travel_mode input {
    opacity: 0;
    position: absolute;
  }
  .google_maps .travel_mode input:checked ~ .travel_mode_radio {
    z-index: 5;
    background: #bdbebf;
    border-color: #b0b1b2;
    color: #707173;
  }
  .google_maps .travel_mode input:checked ~ .travel_mode_radio .icon {
    fill: #707173;
  }
  .google_maps .travel_mode input:focus ~ .travel_mode_radio {
    box-shadow: 0 0 0 0.1875rem rgba(226, 0, 42, 0.2);
    z-index: 15;
    background: #ff1641;
    border-color: #c90025;
    color: #fff;
  }
  .google_maps .travel_mode input:focus ~ .travel_mode_radio .icon {
    fill: #fff;
  }
  .google_maps .travel_mode_entry {
    flex-basis: 100%;
    height: 2.75rem;
  }
  .google_maps .travel_mode_radio {
    position: relative;
    width: 100%;
    background: #fff;
    border: 0.125rem solid #bdbebf;
    color: #707173;
    cursor: pointer;
    display: -webkit-inline-box;
    display: inline-flex;
    -webkit-box-pack: center;
            justify-content: center;
    line-height: 1.8;
    margin-top: 1.8em;
    text-decoration: none;
    text-transform: uppercase;
    -webkit-transition: background 300ms, border 300ms, color 300ms;
    transition: background 300ms, border 300ms, color 300ms;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
  }
  .google_maps .travel_mode_radio.black {
    background: #202020;
    border-color: #202020;
  }
  .google_maps .travel_mode_radio.black:hover {
    background: #E2002A;
    border-color: #202020;
  }
  .google_maps .travel_mode_radio.light {
    background: transparent;
    border-color: #fff;
  }
  .google_maps .travel_mode_radio.light:hover {
    background: #fff;
    border-color: #fff;
    color: #E2002A;
  }
  .google_maps .travel_mode_radio > span {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
            justify-content: center;
    min-height: 2.75rem;
  }
  .google_maps .travel_mode_radio .icon {
    fill: #707173;
    flex-shrink: 0;
    height: 1.5rem;
    -webkit-transition: fill 300ms;
    transition: fill 300ms;
    width: 1.5rem;
  }
  .google_maps .travel_mode_radio .text {
    font-size: 90%;
    font-weight: 700;
    padding: 0.45em 0;
    text-align: center;
  }
  .google_maps .travel_mode_radio .icon + .text {
    padding-left: 0.6em;
  }
  .google_maps .travel_mode_radio .icon {
    fill: #707173;
    min-width: 1.5rem;
    -webkit-transition: fill 300ms;
    transition: fill 300ms;
  }
  .google_maps .travel_mode_radio.driving {
    border-radius: 0.25rem 0 0 0.25rem;
    border-right-width: 0;
  }
  .google_maps .travel_mode_radio.transit,
  .google_maps .travel_mode_radio.bicycling {
    border-left-width: 0;
    border-right-width: 0;
  }
  .google_maps .travel_mode_radio.walking {
    border-left-width: 0;
    border-radius: 0 0.25rem 0.25rem 0;
  }
  .google_maps .warnings {
    background: #f6ecec;
    border-radius: 0.25rem;
    color: #E2002A;
    font-size: 85%;
    margin-bottom: 0.9em;
    padding: 1.2em 1.8em;
  }
  .google_maps .direction {
    margin: 3.6em 0 0 0;
  }
  .google_maps .button_wrapper {
    margin-top: auto;
  }
  .google_maps .panel_wrapper {
    padding-top: 0.9em;
  }
  .google_maps .steps {
    min-width: 100%;
  }
  .google_maps .step {
    border-top: 0.0625rem solid #bdbebf;
    display: -webkit-box;
    display: flex;
  }
  .google_maps .step.last {
    border-bottom: 0.0625rem solid #bdbebf;
  }
  .google_maps .step:before {
    font-size: 85%;
    padding: 0.9em 0;
  }
  .google_maps .instructions,
  .google_maps .distance {
    font-size: 85%;
    padding: 0.9em 0;
  }
  .google_maps .instructions {
    overflow: hidden;
    padding-left: 0.9em;
    width: 100%;
    word-wrap: break-word;
  }
  .google_maps .distance {
    min-width: 5rem;
    text-align: right;
  }
  .google_maps .infowindow {
    max-width: 13.75rem;
  }
  .container {
    margin: 0 auto;
    max-width: 100rem;
    min-width: 17.5rem;
    padding: 0 0.9375rem;
  }
  .row {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
            flex-direction: row;
    flex-wrap: wrap;
    margin-left: -0.46875rem;
    margin-right: -0.46875rem;
  }
  .row.around_xsmall {
    justify-content: space-around;
  }
  .row.between_xsmall {
    -webkit-box-pack: justify;
            justify-content: space-between;
  }
  .row.start_xsmall {
    -webkit-box-pack: start;
            justify-content: flex-start;
  }
  .row.center_xsmall {
    -webkit-box-pack: center;
            justify-content: center;
  }
  .row.end_xsmall {
    -webkit-box-pack: end;
            justify-content: flex-end;
  }
  .row.top_xsmall {
    -webkit-box-align: start;
            align-items: flex-start;
  }
  .row.middle_xsmall {
    -webkit-box-align: center;
            align-items: center;
  }
  .row.bottom_xsmall {
    -webkit-box-align: end;
            align-items: flex-end;
  }
  .row.baseline_xsmall {
    -webkit-box-align: baseline;
            align-items: baseline;
  }
  .row.no_column_margin {
    margin-left: 0;
    margin-right: 0;
  }
  .row.no_column_margin > [class*="span"] {
    padding-left: 0;
    padding-right: 0;
  }
  .row.half_column_margin {
    margin-left: -0.234375rem;
    margin-right: -0.234375rem;
  }
  .row.half_column_margin > [class*="span"] {
    padding-left: 0.234375rem;
    padding-right: 0.234375rem;
  }
  [class*="span"] {
    flex-basis: 100%;
    max-width: 100%;
    padding-left: 0.46875rem;
    padding-right: 0.46875rem;
  }
  [class*="span"].span_column {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
  }
  .span1_xsmall {
    flex-basis: 8.33333333%;
    max-width: 8.33333333%;
  }
  .order1_xsmall {
    -webkit-box-ordinal-group: 2;
            order: 1;
  }
  .span2_xsmall {
    flex-basis: 16.66666667%;
    max-width: 16.66666667%;
  }
  .order2_xsmall {
    -webkit-box-ordinal-group: 3;
            order: 2;
  }
  .span3_xsmall {
    flex-basis: 25%;
    max-width: 25%;
  }
  .order3_xsmall {
    -webkit-box-ordinal-group: 4;
            order: 3;
  }
  .span4_xsmall {
    flex-basis: 33.33333333%;
    max-width: 33.33333333%;
  }
  .order4_xsmall {
    -webkit-box-ordinal-group: 5;
            order: 4;
  }
  .span5_xsmall {
    flex-basis: 41.66666667%;
    max-width: 41.66666667%;
  }
  .order5_xsmall {
    -webkit-box-ordinal-group: 6;
            order: 5;
  }
  .span6_xsmall {
    flex-basis: 50%;
    max-width: 50%;
  }
  .order6_xsmall {
    -webkit-box-ordinal-group: 7;
            order: 6;
  }
  .span7_xsmall {
    flex-basis: 58.33333333%;
    max-width: 58.33333333%;
  }
  .order7_xsmall {
    -webkit-box-ordinal-group: 8;
            order: 7;
  }
  .span8_xsmall {
    flex-basis: 66.66666667%;
    max-width: 66.66666667%;
  }
  .order8_xsmall {
    -webkit-box-ordinal-group: 9;
            order: 8;
  }
  .span9_xsmall {
    flex-basis: 75%;
    max-width: 75%;
  }
  .order9_xsmall {
    -webkit-box-ordinal-group: 10;
            order: 9;
  }
  .span10_xsmall {
    flex-basis: 83.33333333%;
    max-width: 83.33333333%;
  }
  .order10_xsmall {
    -webkit-box-ordinal-group: 11;
            order: 10;
  }
  .span11_xsmall {
    flex-basis: 91.66666667%;
    max-width: 91.66666667%;
  }
  .order11_xsmall {
    -webkit-box-ordinal-group: 12;
            order: 11;
  }
  .span12_xsmall {
    flex-basis: 100%;
    max-width: 100%;
  }
  .order12_xsmall {
    -webkit-box-ordinal-group: 13;
            order: 12;
  }
  header {
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
    z-index: 99;
  }
  header .container {
    width: 100%;
  }
  header .hide_on_small {
    display: none;
  }
  header .header_before {
    background-color: rgba(255, 255, 255, 0.95);
    border-bottom: 0.0625rem solid #d7d7d8;
  }
  header .header_before .text {
    font-size: 75%;
    font-weight: 500;
    vertical-align: super;
    padding-top: 0.72em;
  }
  header .header_before .text.locality {
    margin-right: 0.9em;
  }
  header .header_before .button {
    background-color: transparent;
    border: 0;
    border-radius: 0;
    color: #707173;
    line-height: 0;
    margin-top: 0.36em;
    padding: 0;
    text-transform: none;
  }
  header .header_before .button:focus,
  header .header_before .button:active {
    box-shadow: 0 0 0 0;
  }
  header .header_before .button:hover {
    color: #202020;
  }
  header .header_before .button.separator {
    border-right: 0.125rem solid #d7d7d8;
    padding-right: 0.45em;
  }
  header .header_before .button:last-child {
    padding-left: 0.45em;
  }
  header .header_before .button > span {
    min-height: inherit;
  }
  header .header_before .button .icon {
    fill: #E2002A;
    width: 1rem;
  }
  header .header_before .button .text {
    font-size: 65%;
    font-weight: 500;
    padding-top: 0.72em;
  }
  header .header_before .social_media_links .facebook {
    -webkit-box-align: center;
            align-items: center;
    color: #707173;
    display: none;
    font-weight: 500;
    -webkit-box-pack: center;
            justify-content: center;
    margin-top: 0.3em;
    text-decoration: none;
  }
  header .header_before .social_media_links .facebook:hover {
    color: #202020;
    -webkit-transition: color 300ms;
    transition: color 300ms;
  }
  header .header_before .social_media_links .facebook .icon {
    display: -webkit-inline-box;
    display: inline-flex;
    height: 0.75rem;
    margin-right: 0.45em;
    width: 0.75rem;
  }
  header .header_before .social_media_links .facebook .text {
    font-size: 70%;
    padding: 0.58064516em 0;
  }
  header .header_main {
    background-color: rgba(255, 255, 255, 0.95);
    padding: 1.2em 0;
    width: 100%;
  }
  header .header_main:after {
    background-color: #707173;
    bottom: 0;
    content: "";
    height: 0rem;
    left: 0;
    opacity: 0.2;
    position: absolute;
    right: 0;
    -webkit-transition: all 300ms;
    transition: all 300ms;
    z-index: 9999;
  }
  header .header_main.scrolled_down {
    padding: 1.8em 0;
    position: fixed;
    top: 0;
    -webkit-transition: all 300ms;
    transition: all 300ms;
    z-index: 999;
  }
  header .header_main.scrolled_down:after {
    height: 0.125rem;
    -webkit-transition: all 300ms;
    transition: all 300ms;
  }
  header .header_main.scrolled_down .company_logo {
    max-height: 2.8125rem;
    overflow: hidden;
    -webkit-transition: all 150ms;
    transition: all 150ms;
  }
  header .navigation_wrapper {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
            flex-direction: row;
    -webkit-box-flex: 1;
            flex-grow: 1;
    -webkit-box-pack: end;
            justify-content: flex-end;
  }
  header .some_mitglied_wrapper {
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: end;
            justify-content: flex-end;
  }
  header .some_mitglied_wrapper .mitglied_sdw {
    margin-top: 0.3em;
    margin-bottom: 0.3em;
    margin-right: 0rem;
  }
  header .some_mitglied_wrapper .mitglied_sdw img {
    max-width: 5rem;
    display: block;
  }
  .image {
    position: relative;
    width: 100%;
    z-index: 0;
  }
  .image img {
    display: block;
    margin: 0 auto;
    width: 100%;
  }
  .header_picture {
    max-height: 43.75rem;
    overflow: hidden;
  }
  .image_gallery_overview,
  .image_slider_overview {
    margin-bottom: 1.8em;
    position: relative;
  }
  .image_gallery_entry,
  .image_slider_entry {
    background-color: #d7d7d8;
    border-radius: 0.25rem !important;
    display: block;
    margin: 0.225em;
    overflow: hidden;
    position: relative;
  }
  .image_gallery_entry img,
  .image_slider_entry img {
    display: block;
  }
  .image_gallery_entry:focus,
  .image_slider_entry:focus,
  .image_gallery_entry:hover,
  .image_slider_entry:hover {
    outline: 0;
  }
  .image_gallery_entry:focus .open,
  .image_slider_entry:focus .open,
  .image_gallery_entry:hover .open,
  .image_slider_entry:hover .open {
    opacity: 1;
    visibility: visible;
  }
  .image_gallery_entry:focus .open svg,
  .image_slider_entry:focus .open svg,
  .image_gallery_entry:hover .open svg,
  .image_slider_entry:hover .open svg {
    -webkit-animation: news_photo 900ms;
            animation: news_photo 900ms;
  }
  .image_gallery_entry .open,
  .image_slider_entry .open {
    background: rgba(32, 32, 32, 0.7);
    bottom: 0;
    display: -webkit-box;
    display: flex;
    fill: #fff;
    left: 0;
    opacity: 0;
    position: absolute;
    right: 0;
    top: 0;
    -webkit-transition: opacity 600ms, visibility 600ms;
    transition: opacity 600ms, visibility 600ms;
    visibility: hidden;
  }
  .image_gallery_entry .open svg,
  .image_slider_entry .open svg {
    height: 1.875rem;
    margin: auto;
    width: 1.875rem;
  }
  .image_gallery_entry .image,
  .image_slider_entry .image {
    background-position: 50% !important;
    background-size: cover !important;
    overflow: hidden;
    padding-bottom: 62.5%;
  }
  .image_gallery_overlay {
    background: rgba(32, 32, 32, 0.7);
    bottom: 0;
    height: 100%;
    left: 0;
    opacity: 0;
    position: fixed;
    right: 0;
    top: 0;
    -webkit-transition: opacity 300ms;
    transition: opacity 300ms;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    visibility: hidden;
    width: 100%;
    z-index: 9999;
  }
  .image_gallery_overlay.show {
    opacity: 1;
    visibility: visible;
  }
  .image_gallery_overlay .slider {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    -webkit-transition: -webkit-transform 300ms ease;
    transition: -webkit-transform 300ms ease;
    transition: transform 300ms ease;
    transition: transform 300ms ease, -webkit-transform 300ms ease;
    white-space: nowrap;
    width: 100%;
  }
  .image_gallery_overlay .slider.bounce_from_right {
    -webkit-animation: image_gallery_bounce_from_right 300ms ease-out;
            animation: image_gallery_bounce_from_right 300ms ease-out;
  }
  .image_gallery_overlay .slider.bounce_from_left {
    -webkit-animation: image_gallery_bounce_from_left 300ms ease-out;
            animation: image_gallery_bounce_from_left 300ms ease-out;
  }
  .image_gallery_overlay .full_image {
    display: inline-block;
    height: 100%;
    position: relative;
    text-align: center;
    width: 100%;
  }
  .image_gallery_overlay .full_image:before {
    content: "";
    display: inline-block;
    height: 50%;
    width: 0;
  }
  .image_gallery_overlay .full_image figure {
    display: inline;
    height: 100%;
    margin: 0;
  }
  .image_gallery_overlay .full_image figcaption {
    background: rgba(32, 32, 32, 0.7);
    bottom: 0;
    color: #fff;
    display: block;
    padding: 0.9em;
    position: absolute;
    text-align: center;
    white-space: normal;
    width: 100%;
  }
  .image_gallery_overlay .full_image img {
    display: inline-block;
    height: auto;
    max-height: 100%;
    max-width: 100%;
    opacity: 0;
    -webkit-transition: opacity 300ms;
    transition: opacity 300ms;
    vertical-align: middle;
    visibility: hidden;
    width: auto;
  }
  .image_gallery_overlay .full_image img.show {
    opacity: 1;
    visibility: visible;
  }
  .image_gallery_overlay .spinner {
    display: inline-block;
    height: 1.125rem;
    left: 50%;
    margin-left: -2.1875rem;
    margin-top: -0.5625rem;
    position: absolute;
    top: 50%;
    width: 4.375rem;
  }
  .image_gallery_overlay .spinner .bounce {
    -webkit-animation: image_gallery_spinner 900ms infinite;
            animation: image_gallery_spinner 900ms infinite;
    background: #E2002A;
    border-radius: 100%;
    display: inline-block;
    height: 1.125rem;
    margin: 0 0.25rem;
    width: 1.125rem;
  }
  .image_gallery_overlay .spinner .bounce_1 {
    -webkit-animation-delay: -300ms;
            animation-delay: -300ms;
  }
  .image_gallery_overlay .spinner .bounce_2 {
    -webkit-animation-delay: -150ms;
            animation-delay: -150ms;
  }
  .image_gallery_overlay nav a {
    cursor: pointer;
    display: block;
    position: absolute;
  }
  .image_gallery_overlay nav .previous,
  .image_gallery_overlay nav .next {
    height: 2.75rem;
    margin-top: -1.375rem;
    top: 50%;
    -webkit-transition: background 300ms;
    transition: background 300ms;
    width: 2.75rem;
  }
  .image_gallery_overlay nav .previous:focus .icon,
  .image_gallery_overlay nav .next:focus .icon,
  .image_gallery_overlay nav .previous:hover .icon,
  .image_gallery_overlay nav .next:hover .icon {
    fill: #E2002A;
  }
  .image_gallery_overlay nav .previous .icon,
  .image_gallery_overlay nav .next .icon {
    fill: #fff;
    height: 2.75rem;
    max-height: 2.75rem;
    max-width: 2.75rem;
    min-height: 2.75rem;
    min-width: 2.75rem;
    width: 2.75rem;
  }
  .image_gallery_overlay nav .previous {
    left: 2%;
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
  }
  .image_gallery_overlay nav .next {
    right: 2%;
  }
  .image_gallery_overlay nav .close {
    align-content: center;
    -webkit-box-align: center;
            align-items: center;
    background: #E2002A;
    border: 0.0625rem solid #E2002A;
    border-radius: 50%;
    box-shadow: 0 0 1.25rem rgba(112, 113, 115, 0.7);
    display: -webkit-box;
    display: flex;
    height: 2.75rem;
    -webkit-box-pack: center;
            justify-content: center;
    padding: 0.1875rem;
    position: absolute;
    right: 1.8em;
    top: 1.8em;
    width: 2.75rem;
  }
  .image_gallery_overlay nav .close:focus,
  .image_gallery_overlay nav .close:hover {
    background-color: #ff1641;
    outline: 0;
  }
  .image_gallery_overlay nav .close .icon {
    fill: #fff;
    height: 1.875rem;
    width: 1.875rem;
  }
  .property_headline {
    color: #202020;
    font-size: 120%;
  }
  .properties_map_wrapper {
    margin-left: calc(-50vw + 50%);
    margin-right: calc(-50vw + 50%);
  }
  .properties_map_wrapper .ol-attribution {
    top: 0 !important;
    bottom: auto !important;
  }
  .properties_map_wrapper .map {
    background-color: #F3F1EF;
    height: 31.25rem;
  }
  .properties_map_wrapper .map .gm-style iframe ~ div {
    -webkit-transform: translateY(-3.125rem) !important;
            transform: translateY(-3.125rem) !important;
  }
  .property_details .contact_person_wrapper {
    background-color: #F3F1EF;
    border-radius: 0.25rem;
    padding: 2.7em 1.8em 0.9em 1.8em;
    position: relative;
    top: -1.8em;
  }
  .property_details .navigation {
    align-content: center;
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
    -webkit-box-flex: 1;
            flex-grow: 1;
    -webkit-box-pack: start;
            justify-content: flex-start;
  }
  .property_details .navigation a {
    align-content: center;
    -webkit-box-align: center;
            align-items: center;
    color: #202020;
    display: -webkit-box;
    display: flex;
    font-weight: 700;
    -webkit-box-pack: center;
            justify-content: center;
    text-decoration: none;
    text-transform: uppercase;
  }
  .property_details .navigation a:hover {
    color: #E2002A;
  }
  .property_details .navigation .icon {
    display: inline-block;
    height: 2rem;
    margin-right: 0.9em;
    width: 2rem;
  }
  .property_details .request_property h2 {
    font-size: 180%;
    margin-bottom: 0;
  }
  .property_details .contact_person_overview h2 {
    font-size: 120%;
    margin-bottom: 0.45em;
    padding-top: 0.9em;
  }
  .property_details .contact_person_overview .contact_person {
    display: -webkit-box;
    display: flex;
    flex-wrap: wrap;
  }
  .property_details .contact_person_overview .contact_person p {
    display: -webkit-box;
    display: flex;
    flex-wrap: wrap;
  }
  .property_details .contact_person_overview .contact_person h3 {
    font-size: 100%;
    line-height: 1.8;
    margin: 0 2.7em 0 0;
    padding: 0;
  }
  .property_details .contact_person_overview .contact_person .contact {
    margin-right: 0.9em;
  }
  .property_details .contact_person_overview .contact_person .contact a {
    color: #202020;
  }
  .property_details .details_group {
    padding: 1.2em 0 1.44em;
  }
  .property_details .details_group ul:last-child {
    margin: 0;
  }
  .property_details .details_group[data-property-details="hidden"] {
    display: none;
  }
  .property_details .details_group[data-property-details="visible"] {
    display: block;
  }
  .property_details .more_details {
    -webkit-box-align: center;
            align-items: center;
    color: #707173;
    display: -webkit-inline-box;
    display: inline-flex;
    font-family: "Rubik", sans-serif;
    font-weight: 700;
    margin-top: 0.9em;
    padding: 0.45em 0;
    text-decoration: none;
    text-transform: uppercase;
  }
  .property_details .more_details:focus,
  .property_details .more_details:hover {
    color: #E2002A;
    outline: 0;
  }
  .property_details .more_details .icon {
    display: block;
    fill: #E2002A;
    height: 1.25rem;
    margin-left: 0.6em;
    margin-right: 0.6em;
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
    -webkit-transition: all 150ms ease;
    transition: all 150ms ease;
    width: 1.25rem;
  }
  .property_details .more_details[aria-pressed="true"] .icon {
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg);
    -webkit-transition: all 150ms ease;
    transition: all 150ms ease;
  }
  .find_properties {
    position: relative;
    width: 100%;
    z-index: 300;
  }
  .find_properties .title_wrapper {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
    width: 100%;
  }
  .find_properties .title_wrapper h2 {
    color: #fff;
    font-family: "Rubik", sans-serif;
    font-size: 128.57142857%;
    margin: 0;
    padding: 0 1.8em 0.9em 0;
  }
  .find_properties .form .select {
    border-color: #fff;
  }
  .find_properties .form .select.focus,
  .find_properties .form .select:hover {
    border-color: #202020;
  }
  .find_properties .form .checkbox .indicator {
    border-color: #fff;
  }
  .find_properties .form .select_wrapper .entry {
    margin-bottom: 0.36em;
    width: 100%;
  }
  .find_properties .form .select_wrapper .entry .select_search {
    display: block;
    position: relative;
  }
  .find_properties .form .select_wrapper .entry .loader {
    display: none;
  }
  .find_properties .form .select_wrapper .entry .loader.animated {
    -webkit-animation: loader 600ms linear infinite;
            animation: loader 600ms linear infinite;
    border-color: #E2002A #8D836C #fff #fff;
    border-radius: 100%;
    border-style: solid;
    border-width: 0.3125rem;
    display: block;
    height: 1.875rem;
    margin-top: -0.9375rem;
    position: absolute;
    right: 4.125rem;
    top: 50%;
    width: 1.875rem;
  }
  .find_properties .form .checkbox_wrapper {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
            flex-direction: row;
    position: relative;
  }
  .find_properties .form .checkbox_wrapper .checkbox {
    margin-right: 1.8em;
  }
  .find_properties .form .checkbox_wrapper .control {
    margin-bottom: 0;
  }
  .find_properties .form .buttons {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    -webkit-box-pack: center;
            justify-content: center;
    margin-bottom: 1.2em;
    margin-top: 1.2em;
    min-width: 7.5rem;
  }
  .find_properties .form .buttons .button {
    margin: 0;
  }
  .find_properties .form [data-toggle-search-options] {
    color: #fff;
    text-decoration: none;
  }
  .find_properties .form [data-toggle-search-options][aria-expanded="true"] .icon {
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg);
    -webkit-transition: -webkit-transform 150ms;
    transition: -webkit-transform 150ms;
    transition: transform 150ms;
    transition: transform 150ms, -webkit-transform 150ms;
  }
  .find_properties .form [data-toggle-search-options] .icon {
    display: -webkit-inline-box;
    display: inline-flex;
    fill: #fff;
    height: 0.875rem;
    margin-right: 0.18em;
    margin-top: 0.6em;
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
    -webkit-transition: -webkit-transform 150ms;
    transition: -webkit-transform 150ms;
    transition: transform 150ms;
    transition: transform 150ms, -webkit-transform 150ms;
    width: 0.875rem;
  }
  .find_properties .form [data-toggle-search-options]:focus {
    color: #fff;
    outline: 0;
  }
  .find_properties .form [data-toggle-search-options]:focus .text {
    text-decoration: underline;
  }
  .find_properties .form [data-toggle-search-options]:hover .text {
    text-decoration: underline;
  }
  .find_properties .form [data-toggle-search-options] .text {
    text-decoration: none;
  }
  .find_properties .form [data-search-options="hidden"] {
    display: none;
  }
  .find_properties .form [data-search-options="visible"] {
    margin-top: 0.9em;
  }
  .properties_nav .objects_found {
    line-height: 3;
    margin: 0;
  }
  .properties_nav .pagination {
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: end;
            justify-content: flex-end;
  }
  .properties_nav .pagination a {
    background-color: #202020;
    border-radius: 0.25rem;
    color: #fff;
    margin-left: 0.45em;
    padding: 0.6em 1.2em;
    text-decoration: none;
  }
  .properties_nav .pagination a.active {
    background-color: #E2002A;
  }
  .properties_search_results {
    margin-top: 0;
  }
  .properties_search_results .entry {
    border: 0.125rem solid #d7d7d8;
    border-radius: 0.25rem;
    margin: 1.8em 0;
    overflow: hidden;
    position: relative;
    -webkit-transition: all 300ms;
    transition: all 300ms;
  }
  .properties_search_results .entry:before {
    background: url("../images/lens.svg") no-repeat !important;
    background-color: rgba(0, 0, 0, 0.6) !important;
    background-position: center center !important;
    background-size: 2.5rem 2.5rem !important;
    bottom: 0;
    content: "";
    left: 0;
    opacity: 0;
    position: absolute;
    right: 0;
    top: 0;
    -webkit-transition: opacity 300ms;
    transition: opacity 300ms;
    width: 100%;
    z-index: 3;
  }
  .properties_search_results .entry:hover {
    border-color: rgba(0, 0, 0, 0.6);
    -webkit-transition: all 300ms;
    transition: all 300ms;
  }
  .properties_search_results .entry:hover:before {
    opacity: 1;
    -webkit-transition: opacity 300ms;
    transition: opacity 300ms;
  }
  .properties_search_results .entry:hover .property_details_btn {
    opacity: 0;
    -webkit-transition: all 300ms;
    transition: all 300ms;
  }
  .properties_search_results .entry .photo_wrapper {
    display: -webkit-box;
    display: flex;
    -webkit-box-flex: 1;
            flex-grow: 1;
    min-height: 12.5rem;
  }
  .properties_search_results .entry .photo_wrapper a {
    background-position: center center !important;
    background-repeat: no-repeat;
    background-size: cover !important;
    text-decoration: none;
    width: 100%;
  }
  .properties_search_results .entry .property_details_btn {
    -webkit-box-align: end;
            align-items: flex-end;
    bottom: 0;
    color: #fff;
    display: -webkit-box;
    display: flex;
    font-size: 90%;
    font-weight: 500;
    -webkit-box-pack: center;
            justify-content: center;
    left: 0;
    opacity: 0;
    padding-bottom: 3.6em;
    position: absolute;
    right: 0;
    text-decoration: none;
    text-transform: uppercase;
    top: 0;
    -webkit-transition: all 300ms;
    transition: all 300ms;
    z-index: 4;
  }
  .properties_search_results .entry .text_wrapper {
    -webkit-box-flex: 1;
            flex: 1 1 auto;
    padding: 1.2em 2.7em;
  }
  .properties_search_results .entry .text_wrapper h3 {
    color: #E2002A;
    text-transform: uppercase;
  }
  .properties_search_results .entry .details {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
            flex-direction: row;
    flex-wrap: wrap;
    padding-left: 0;
  }
  .properties_search_results .entry .details li {
    flex-shrink: 0;
    list-style: none;
    padding-left: 0;
    padding-right: 2.7em;
  }
  .search_agent {
    margin-bottom: 2.7em;
    background: #F3F1EF;
    border-radius: 0.25rem;
    padding: 1.8em;
    position: relative;
    margin-top: -1.8em;
  }
  .search_agent .search_agent_wrapper {
    margin: 1.8em 0 0 0;
  }
  .search_agent .search_agent_wrapper .button {
    margin-top: 0;
  }
  .search_agent .search_agent_wrapper h2 {
    font-size: 130%;
    margin-bottom: 0.45em;
    padding: 0;
  }
  .search_agent .search_agent_wrapper ol {
    left: -0.3rem;
    position: relative;
  }
  .search_agent .search_agent_wrapper ol li {
    margin-right: 0.9em;
  }
  .search_agent .search_agent_wrapper .button {
    -webkit-box-flex: 1;
            flex: 1 0 auto;
    width: 100%;
  }
  .dialog_search_agent h2 {
    margin-bottom: 0.9em;
  }
  .dialog_search_agent h3 {
    margin: 0;
    padding: 0;
    text-transform: none;
  }
  .dialog_search_agent .search_agent_settings {
    margin-bottom: 0.9em;
  }
  .legal_notice_tax_aut_aab {
    margin-bottom: 0.9em;
    padding-top: 0.9em;
  }
  .legal_notice_tax_aut_aab [href$=".pdf"] {
    display: block;
  }
  .legal_notice_tax_aut_aab [href$=".pdf"] > span {
    display: -webkit-box;
    display: flex;
  }
  .legal_notice_tax_aut_aab .icon {
    display: block;
    fill: #e44e4e;
    height: 1.5rem;
    margin-right: 0.72em;
    min-width: 1.5rem;
    width: 1.5rem;
  }
  main {
    padding: 0 0 7.2em 0;
  }
  .main_divider {
    background-color: #F3F1EF;
    height: 0.9375rem;
    margin: 2.7em 0;
    width: 100%;
  }
  .current_project .entry {
    margin-left: 0 !important;
  }
  .estate_object_picture {
    background-position: center center !important;
    background-size: cover !important;
    height: 25rem;
    margin-bottom: 3.6em;
  }
  .badges {
    display: -webkit-inline-box;
    display: inline-flex;
    height: 3.125rem;
    position: relative;
    top: 1.25rem;
  }
  .badges .link_apple,
  .badges .link_google {
    width: 8.125rem;
  }
  .badges .link_apple {
    margin-right: 1em;
  }
  .startpage main {
    padding: 0 0 0 0;
  }
  .startpage_special_title {
    margin-top: 2.7em;
  }
  .bupm .teaser_overview {
    margin-bottom: 9em;
  }
  .bupm .current_objects {
    margin-left: calc(-50vw + 50%);
    margin-right: calc(-50vw + 50%);
  }
  .margin_top_2 {
    margin-top: 3.96em;
  }
  .margin_bottom_4 {
    margin-bottom: 7.2em;
  }
  .fullwidth_section {
    margin-left: calc(-50vw + 50%);
    margin-right: calc(-50vw + 50%);
  }
  .main_section {
    padding: 3.6em 0;
  }
  .main_section.gray {
    background-color: #F3F1EF;
    position: relative;
  }
  .main_section.gray:before,
  .main_section.gray:after {
    background: #F3F1EF;
    bottom: 0;
    content: "";
    position: absolute;
    top: 0;
  }
  .main_section.gray:before {
    left: -125rem;
    right: 100%;
  }
  .main_section.gray:after {
    left: 100%;
    right: -125rem;
  }
  .testimonial_overview {
    background-color: #F3F1EF;
    border-radius: 0.25rem;
    margin-bottom: 9em;
  }
  .testimonials_slider {
    position: relative;
  }
  .testimonials_slider:before {
    background: url("../images/quote.svg") no-repeat;
    background-position: center center;
    background-size: 5rem;
    content: "";
    height: 5rem;
    left: -1.25rem;
    position: absolute;
    top: 0.9375rem;
    width: 5rem;
  }
  .testimonials_slider p {
    margin: 0;
  }
  .testimonials_slider p em {
    font-style: italic;
  }
  .testimonials_slider .slide {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    left: 0;
    opacity: 0;
    padding: 2.7em 2.7em 1.8em 2.7em;
    position: absolute;
    right: 0;
    top: 0;
    -webkit-transition: opacity 150ms ease-out;
    transition: opacity 150ms ease-out;
  }
  .testimonials_slider .slide.active {
    display: -webkit-box;
    display: flex;
    opacity: 1;
    position: relative;
    -webkit-transition: opacity 150ms ease-out;
    transition: opacity 150ms ease-out;
    width: 100%;
  }
  .testimonials_slider .info {
    margin-bottom: 2.7em;
    margin-left: 3.6em;
  }
  .testimonials_slider .pagination_wrapper {
    bottom: -3.75rem;
    display: -webkit-box;
    display: flex;
    height: 1.875rem;
    -webkit-box-pack: center;
            justify-content: center;
    left: 0;
    margin: 0 auto;
    position: relative;
    right: 0;
    width: 100%;
    z-index: 9;
  }
  .testimonials_slider .pagination_wrapper .pagination {
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
            justify-content: center;
  }
  .testimonials_slider .pagination_wrapper .pagination a {
    background-color: #fff;
    border-radius: 50%;
    box-shadow: 0 0 0.625rem 0 rgba(0, 0, 0, 0.4);
    display: block;
    height: 0.75rem;
    margin: 0 0.5em;
    position: relative;
    -webkit-transition: background 150ms ease-out;
    transition: background 150ms ease-out;
    width: 0.75rem;
  }
  .testimonials_slider .pagination_wrapper .pagination a.active {
    background-color: #E2002A;
  }
  .testimonials_slider .pagination_wrapper .pagination a.active:after {
    background-color: #E2002A;
  }
  .reference_overview {
    margin-left: calc(-50vw + 50%);
    margin-right: calc(-50vw + 50%);
  }
  .reference_overview .reference_entry {
    background-position: center center !important;
    background-size: cover !important;
    color: #fff;
    display: -webkit-box;
    display: flex;
    -webkit-box-flex: 1;
            flex: 1 1 auto;
    min-height: 21.6em;
    position: relative;
    text-decoration: none;
  }
  .reference_overview .reference_entry:hover:before {
    opacity: 1;
    -webkit-transition: opacity 300ms;
    transition: opacity 300ms;
  }
  .reference_overview .reference_entry:hover .description {
    align-content: center;
    -webkit-box-align: center;
            align-items: center;
    align-self: center;
    -webkit-box-pack: center;
            justify-content: center;
    -webkit-transition: all 300ms;
    transition: all 300ms;
  }
  .reference_overview .reference_entry:hover .description .short_description {
    display: block;
    text-align: center;
  }
  .reference_overview .reference_entry:hover .short_description .name,
  .reference_overview .reference_entry:hover .short_description .location {
    display: block !important;
  }
  .reference_overview .reference_entry:hover .description .name {
    display: none;
    text-align: center;
  }
  .reference_overview .reference_entry:hover .description .location {
    display: none;
    text-align: center;
  }
  .reference_overview .reference_entry:before {
    background-color: rgba(0, 0, 0, 0.6);
    bottom: 0;
    content: "";
    left: 0;
    opacity: 0;
    position: absolute;
    right: 0;
    top: 0;
    -webkit-transition: opacity 300ms;
    transition: opacity 300ms;
    z-index: 3;
  }
  .reference_overview .reference_entry:after {
    background: -webkit-linear-gradient(bottom, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0) 90%, rgba(0, 0, 0, 0) 100%);
    background: linear-gradient(to top, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0) 90%, rgba(0, 0, 0, 0) 100%);
    bottom: 0;
    content: "";
    left: 0;
    position: absolute;
    right: 0;
    top: 50%;
    z-index: 1;
  }
  .reference_overview .reference_entry .description {
    align-self: flex-end;
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
            flex-direction: column-reverse;
    padding: 1.8em;
    position: relative;
    -webkit-transition: all 300ms;
    transition: all 300ms;
    width: 100%;
    z-index: 4;
  }
  .reference_overview .reference_entry .description .name {
    font-weight: 700;
    text-transform: uppercase;
    width: 100%;
  }
  .reference_overview .reference_entry .description .short_description {
    display: none;
    text-align: center;
    width: 100%;
  }
  .stoerer {
    align-content: center;
    -webkit-box-align: center;
            align-items: center;
    background-color: #E2002A;
    border-radius: 50%;
    color: #fff;
    display: none;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    height: 16.25rem;
    -webkit-box-pack: center;
            justify-content: center;
    position: absolute;
    right: -3.125rem;
    top: -7.5rem;
    -webkit-transform: scale(0);
            transform: scale(0);
    width: 16.25rem;
    z-index: 9;
  }
  .stoerer.animated {
    -webkit-animation-delay: 0.5s;
            animation-delay: 0.5s;
    -webkit-animation-duration: 450ms;
            animation-duration: 450ms;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
    -webkit-animation-name: appear;
            animation-name: appear;
    -webkit-animation-timing-function: ease-in-out;
            animation-timing-function: ease-in-out;
  }
  .stoerer .overview_title {
    color: #fff;
    font-size: 1.25rem;
    padding: 1.125em 2.88em 0 2.88em;
    text-align: center;
    width: 100%;
  }
  .stoerer .description {
    width: 95%;
  }
  .stoerer .title_wrapper {
    width: 100%;
  }
  .stoerer .title_wrapper .title {
    color: #fff;
    font-weight: 400;
    padding: 0.9em 1.8em 0 1.8em;
    text-align: center;
    text-decoration: none;
    width: 100%;
  }
  .stoerer .title_wrapper small {
    display: none;
  }
  .stoerer .icon {
    fill: #fff;
    height: 2.875rem;
    margin: 0 auto;
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transition: -webkit-transform 150ms;
    transition: -webkit-transform 150ms;
    transition: transform 150ms;
    transition: transform 150ms, -webkit-transform 150ms;
    width: 2.875rem;
  }
  .stoerer .touch_link {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
  }
  .stoerer .touch_link:hover .icon {
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
    -webkit-transition: -webkit-transform 150ms;
    transition: -webkit-transform 150ms;
    transition: transform 150ms;
    transition: transform 150ms, -webkit-transform 150ms;
  }
  .figure {
    border-radius: 0.25rem;
    margin-top: 3.6em;
    overflow: hidden;
  }
  .multibox {
    background-color: #E2002A;
    border-radius: 0.25rem;
    color: #fff;
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    -webkit-box-pack: center;
            justify-content: center;
    margin-top: -2.7em;
    padding: 1.8em;
    position: relative;
    width: 100%;
    z-index: 9;
  }
  .multibox p {
    font-size: 100%;
    text-align: center;
  }
  .multibox .button_wrapper {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    -webkit-box-flex: 1;
            flex-grow: 1;
    -webkit-box-pack: center;
            justify-content: center;
  }
  .multibox .button_wrapper .button {
    margin: 0.9em 0;
  }
  .multibox .title {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
            flex-direction: column-reverse;
    -webkit-box-pack: center;
            justify-content: center;
  }
  .multibox .title h3 {
    color: #fff;
    text-align: center;
    text-transform: uppercase;
  }
  .multibox .title .icon {
    fill: #fff;
    flex-shrink: 0;
    height: 2.625rem;
    margin: 0 auto;
    width: 2.625rem;
  }
  .projects_cards_overview .entry {
    align-content: flex-start;
    background-position: center top !important;
    background-size: cover !important;
    border: 0.0625rem solid #dadadb;
    border-radius: 0.25rem;
    color: #fff;
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    flex-shrink: 0;
    flex-wrap: nowrap;
    -webkit-box-pack: start;
            justify-content: flex-start;
    margin: 0 0 1.8em 0;
    min-height: 18.75rem;
    position: relative;
    -webkit-transition: all 150ms;
    transition: all 150ms;
    width: 100%;
  }
  .projects_cards_overview .entry:before {
    background-color: rgba(0, 0, 0, 0.6);
    bottom: 0;
    content: "";
    left: 0;
    opacity: 0;
    position: absolute;
    right: 0;
    top: 0;
    -webkit-transition: opacity 300ms;
    transition: opacity 300ms;
    z-index: 3;
  }
  .projects_cards_overview .entry:after {
    background: -webkit-linear-gradient(bottom, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0) 90%, rgba(0, 0, 0, 0) 100%);
    background: linear-gradient(to top, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0) 90%, rgba(0, 0, 0, 0) 100%);
    bottom: 0;
    content: "";
    left: 0;
    position: absolute;
    right: 0;
    top: 50%;
    z-index: 1;
  }
  .projects_cards_overview .entry img {
    position: absolute;
    margin-top: auto;
    margin-bottom: auto;
    top: 0;
    bottom: 0;
  }
  .projects_cards_overview .entry:hover {
    border-radius: 0.25rem;
    box-shadow: 0rem 0.125rem 0.9375rem 0rem rgba(0, 0, 0, 0.1);
    color: #fff;
    -webkit-transform: scale(0.95);
            transform: scale(0.95);
    -webkit-transition: all 150ms;
    transition: all 150ms;
  }
  .projects_cards_overview .entry:hover .title {
    color: #E2002A;
    text-decoration: none;
  }
  .projects_cards_overview .description {
    align-self: auto;
    border-bottom-left-radius: 0.25rem;
    border-top-left-radius: 0.25rem;
    display: -webkit-box;
    display: flex;
    flex-basis: auto;
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
            flex-direction: column-reverse;
    -webkit-box-flex: 1;
            flex-grow: 1;
    flex-shrink: 1;
    margin: -0.0625rem;
    padding: 1.8em;
    width: 100%;
    z-index: 4;
  }
  .projects_cards_overview .description .title {
    font-size: 110%;
    margin: 0 0 0.45em 0;
  }
  .projects_cards_overview .description .name {
    font-weight: 700;
    text-transform: uppercase;
    width: 100%;
  }
  .service_slider {
    position: relative;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
  }
  .service_slider:hover .controlls .control_slides {
    opacity: 1;
    -webkit-transition: opacity 300ms;
    transition: opacity 300ms;
  }
  .service_slider .header_picture {
    max-height: 50rem;
    overflow: hidden;
  }
  .service_slider .pagination_wrapper {
    display: none;
    height: 2.5rem;
    -webkit-box-pack: center;
            justify-content: center;
    left: 0;
    margin: 0 auto;
    position: absolute;
    right: 0;
    top: 0rem;
    width: 100%;
    z-index: 999;
  }
  .service_slider .pagination_wrapper .pagination {
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
            justify-content: center;
  }
  .service_slider .pagination_wrapper .pagination a {
    background-color: #fff;
    border-radius: 50%;
    box-shadow: 0 0 0.625rem 0 rgba(0, 0, 0, 0.4);
    display: block;
    height: 0.75rem;
    margin: 0 0.5em;
    position: relative;
    -webkit-transition: background 150ms ease-out;
    transition: background 150ms ease-out;
    width: 0.75rem;
  }
  .service_slider .pagination_wrapper .pagination a:after {
    background-color: transparent;
    content: "";
    height: 1.25rem;
    left: -0.25rem;
    position: absolute;
    top: 2.16em;
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
    width: 1.25rem;
  }
  .service_slider .pagination_wrapper .pagination a.active {
    background-color: #E2002A;
  }
  .service_slider .pagination_wrapper .pagination a.active:after {
    background-color: #E2002A;
  }
  .service_slider .slide {
    left: 0;
    opacity: 0;
    position: absolute;
    right: 0;
    top: 0;
    -webkit-transition: opacity 150ms ease-out;
    transition: opacity 150ms ease-out;
  }
  .service_slider .slide.active {
    opacity: 1;
    position: relative;
    -webkit-transition: opacity 150ms ease-out;
    transition: opacity 150ms ease-out;
    width: 100%;
  }
  .service_slider .slide.active + .multibox_wrapper {
    opacity: 1;
    position: relative;
    width: 100%;
  }
  .service_slider .multibox_wrapper {
    left: 0;
    opacity: 0;
    position: absolute;
    right: 0;
    top: 0;
  }
  .service_slider .controlls .control_slides {
    display: none;
    height: 0.625rem;
    width: 0.625rem;
  }
  .service_slider .controlls .prev_slide {
    left: 2.16em;
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
  }
  .service_slider .controlls .next_slide {
    right: 2.16em;
  }
  .startpage_cta {
    background: #E2002A;
    padding: 3.6em 0;
  }
  .startpage_cta .cta_text h3 {
    color: #fff;
    font-size: 1.75rem;
    margin: 0;
    padding: 0;
    text-transform: uppercase;
  }
  .startpage_cta .cta_text h4 {
    color: #fff;
  }
  .company_app {
    padding: 5.4em 0 0 0;
  }
  .company_app .smartphone {
    -webkit-box-align: center;
            align-items: center;
    align-self: auto;
    display: -webkit-box;
    display: flex;
    -webkit-box-flex: 1;
            flex: 1 1 auto;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    -webkit-box-pack: end;
            justify-content: flex-end;
    overflow: hidden;
    position: relative;
  }
  .company_app .smartphone .figure {
    border-radius: 0;
    margin: 0 auto -50rem auto;
    -webkit-transition: all 600ms;
    transition: all 600ms;
    width: 90%;
  }
  .company_app .smartphone.animated .figure {
    margin-bottom: 0rem;
    -webkit-transition: all 600ms;
    transition: all 600ms;
  }
  .company_app .app_text {
    padding-bottom: 7.2em;
  }
  .current_objects {
    margin-top: 1.2em;
  }
  .current_objects .last {
    display: none;
  }
  .current_objects .project_entry {
    background-position: center center !important;
    background-size: cover !important;
    color: #fff;
    display: -webkit-box;
    display: flex;
    -webkit-box-flex: 1;
            flex: 1 1 auto;
    min-height: 19.8em;
    position: relative;
    text-decoration: none;
  }
  .current_objects .project_entry:hover:before {
    opacity: 1;
    -webkit-transition: opacity 300ms;
    transition: opacity 300ms;
  }
  .current_objects .project_entry img {
    position: absolute;
    margin-top: auto;
    margin-bottom: auto;
    top: 0;
    bottom: 0;
  }
  .current_objects .project_entry:before {
    background: url("../images/lens.svg") no-repeat;
    background-color: rgba(0, 0, 0, 0.6);
    background-position: center center;
    background-size: 2.5rem 2.5rem !important;
    bottom: 0;
    content: "";
    left: 0;
    opacity: 0;
    position: absolute;
    right: 0;
    top: 0;
    -webkit-transition: opacity 300ms;
    transition: opacity 300ms;
    z-index: 3;
  }
  .current_objects .project_entry:after {
    background: -webkit-linear-gradient(bottom, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0) 90%, rgba(0, 0, 0, 0) 100%);
    background: linear-gradient(to top, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0) 90%, rgba(0, 0, 0, 0) 100%);
    bottom: 0;
    content: "";
    left: 0;
    position: absolute;
    right: 0;
    top: 50%;
    z-index: 1;
  }
  .current_objects .project_entry .description {
    align-self: flex-end;
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
            flex-direction: column-reverse;
    padding: 1.8em;
    position: relative;
    width: 100%;
    z-index: 4;
  }
  .current_objects .project_entry .description .title {
    font-weight: 700;
    text-transform: uppercase;
    width: 100%;
  }
  .gototop {
    align-content: center;
    -webkit-box-align: center;
            align-items: center;
    background: #202020;
    border-radius: 0.25rem;
    bottom: 0;
    display: -webkit-inline-box;
    display: inline-flex;
    height: 3rem;
    -webkit-box-pack: center;
            justify-content: center;
    line-height: 5rem;
    opacity: 0;
    position: fixed;
    right: 1rem;
    text-align: center;
    -webkit-transition: all .3s;
    transition: all .3s;
    visibility: hidden;
    width: 3rem;
    z-index: 999;
  }
  .gototop:hover {
    background: #8D836C;
  }
  .gototop .icon {
    display: block;
    fill: #fff;
    height: 1.4rem;
    line-height: 1rem;
    margin-top: 0;
    min-width: 0.125rem;
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg);
    width: 1.4rem;
  }
  .gototop.show {
    bottom: 4rem;
    opacity: .8;
    -webkit-transition: all .7s;
    transition: all .7s;
    visibility: visible;
  }
  .image_slider_overview {
    border-radius: 0.25rem;
    height: auto;
    margin-top: 1.8em;
    width: auto;
  }
  .image_slider {
    position: relative;
  }
  .image_slider .slide {
    bottom: 0;
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    height: 18.75rem;
    left: 0;
    margin: 0;
    opacity: 0;
    position: absolute;
    right: 0;
    top: 0;
    -webkit-transition: opacity 150ms ease-out;
    transition: opacity 150ms ease-out;
  }
  .image_slider .slide .image {
    background-size: cover;
    height: 18.75rem;
    margin-top: 0;
    overflow: hidden;
  }
  .image_slider .slide.active {
    display: -webkit-box;
    display: flex;
    opacity: 1;
    position: relative;
    -webkit-transition: opacity 150ms ease-out;
    transition: opacity 150ms ease-out;
    width: 100%;
    z-index: 2;
  }
  .image_slider .pagination_wrapper {
    bottom: 2.5rem;
    display: -webkit-box;
    display: flex;
    height: 1.875rem;
    -webkit-box-pack: center;
            justify-content: center;
    left: 0;
    margin: 0 auto;
    position: relative;
    right: 0;
    width: 100%;
    z-index: 2;
  }
  .image_slider .pagination_wrapper .pagination {
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
            justify-content: center;
  }
  .image_slider .pagination_wrapper .pagination a {
    background-color: #fff;
    border-radius: 50%;
    box-shadow: 0 0 0.625rem 0 rgba(0, 0, 0, 0.4);
    display: block;
    height: 0.75rem;
    margin: 0 0.5em;
    position: relative;
    -webkit-transition: background 150ms ease-out;
    transition: background 150ms ease-out;
    width: 0.75rem;
  }
  .image_slider .pagination_wrapper .pagination a.active {
    background-color: #E2002A;
  }
  .image_slider .pagination_wrapper .pagination a.active:after {
    background-color: #E2002A;
  }
  .current_projects_headline {
    text-align: left;
  }
  .current_project .entry {
    display: -webkit-inline-box;
    display: inline-flex;
    -webkit-box-pack: center;
            justify-content: center;
    margin: 1.8em 0 0 0;
    width: 100%;
  }
  .current_project .entry .title {
    color: #E2002A;
    letter-spacing: calc((1.1875rem + -0.0625
                                 * (100vw - 17.5rem)
                                 / (93.75 - 17.5))
                                 / 1000 * 0);
    font-size: 1.1875rem;
    padding-right: 1.8em;
  }
  .current_project .entry .info {
    background: #F3F1EF;
    border-radius: 0.25rem;
    display: -webkit-inline-box;
    display: inline-flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    -webkit-box-pack: center;
            justify-content: center;
    margin-right: 3.6em;
    padding: 1.8em 3.6em 1.8em 2.7em;
    position: relative;
    width: 100%;
  }
  .current_project .entry .info .photo {
    min-height: 9.375rem;
    position: absolute;
    right: -1.8em;
  }
  .current_project .entry .info .photo img {
    min-height: 9.375rem;
  }
  .red_main {
    background: #E2002A;
    color: #fff;
    margin-left: calc(-50vw + 50%);
    margin-right: calc(-50vw + 50%);
    padding: 9em 0 0;
    z-index: 10 !important;
  }
  .red_main p {
    margin-bottom: 3.6em;
  }
  .red_main h2,
  .red_main h3 {
    color: #fff;
    text-transform: uppercase;
  }
  .red_main h3 {
    letter-spacing: calc((1rem + 1
                                 * (100vw - 17.5rem)
                                 / (93.75 - 17.5))
                                 / 1000 * 0);
    font-size: 1rem;
  }
  .red_main .figure {
    margin-bottom: 0;
    margin-right: calc(-50vw + 50%);
  }
  .red_main .ipad {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    -webkit-box-pack: center;
            justify-content: center;
  }
  .arev_app {
    background: #F3F1EF;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center !important;
            justify-content: center !important;
    margin-left: calc(-50vw + 50%);
    margin-right: calc(-50vw + 50%);
    padding: 7.2em 0 3.6em;
    z-index: 1;
  }
  .arev_app .container {
    width: 100%;
  }
  .arev_app .figure {
    margin-bottom: -9em;
    z-index: 0;
  }
  .arev_app .smartphone .figure {
    margin: 0 auto 0 auto;
  }
  .arev_app .app_text {
    padding: 5.4em 0;
  }
  .arev_app .app_text .badges a {
    margin-left: 0;
  }
  .download_section {
    background: #F3F1EF;
    color: #202020;
    display: -webkit-box;
    display: flex;
    margin-left: calc(-50vw + 50%);
    margin-right: calc(-50vw + 50%);
    padding-top: 9em;
    text-align: center;
  }
  .download_section .container {
    width: 100%;
  }
  .download_section .download_now p {
    margin-bottom: 0;
  }
  .download_section h2 {
    color: #202020;
    letter-spacing: calc((1.125rem + 0.875
                                 * (100vw - 17.5rem)
                                 / (93.75 - 17.5))
                                 / 1000 * 0);
    font-size: 1.125rem;
  }
  .download_section .badges {
    margin-bottom: 9em;
  }
  .download_section .figure_overview {
    -webkit-box-align: center;
            align-items: center;
    background: #F3F1EF;
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
            flex-direction: row;
    -webkit-box-pack: center;
            justify-content: center;
    margin-bottom: -6.75em;
    margin-left: calc(-50vw + 50%);
    margin-right: calc(-50vw + 50%);
  }
  .download_section .figure_overview .figure {
    margin: 0 0.9em;
  }
  .login_link {
    display: block;
    margin: 1.8em 0;
  }
  .main_menu {
    display: none;
  }
  .newsletter_quick_subscribe form {
    display: block;
  }
  .newsletter_quick_subscribe .entry {
    width: 100%;
  }
  .newsletter_quick_subscribe input[type="email"] {
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none;
    background: #fff;
    border: 0.0625rem solid #bdbebf;
    border-radius: 0.25rem;
    box-shadow: 0 0 0 #bdbebf inset;
    color: #707173;
    height: 2.75rem;
    margin: 0;
    padding: 0 0.9em;
    -webkit-transition: background 300ms, border-color 300ms, box-shadow 300ms, color 300ms, outline 300ms;
    transition: background 300ms, border-color 300ms, box-shadow 300ms, color 300ms, outline 300ms;
    width: 100%;
  }
  .newsletter_quick_subscribe input[type="email"]:focus {
    background: #fff;
    border-color: #202020;
    box-shadow: 0 0 0.1875rem #202020 inset, 0 0 0 0.1875rem rgba(32, 32, 32, 0.2);
    color: #707173;
    outline: 0;
  }
  .newsletter_quick_subscribe button {
    background: #E2002A;
    border: 0.125rem solid #E2002A;
    color: #fff;
    cursor: pointer;
    display: -webkit-inline-box;
    display: inline-flex;
    line-height: 1.8;
    margin-top: 1.8em;
    text-decoration: none;
    text-transform: uppercase;
    -webkit-transition: background 300ms, border 300ms, color 300ms;
    transition: background 300ms, border 300ms, color 300ms;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    border-radius: 0.25rem;
    display: -webkit-box;
    display: flex;
    -webkit-box-flex: 1;
            flex: 1 0 auto;
    -webkit-box-pack: center;
            justify-content: center;
    margin: 0;
    padding: 0 1.8em;
  }
  .newsletter_quick_subscribe button.black {
    background: #202020;
    border-color: #202020;
  }
  .newsletter_quick_subscribe button.black:hover {
    background: #E2002A;
    border-color: #202020;
  }
  .newsletter_quick_subscribe button.light {
    background: transparent;
    border-color: #fff;
  }
  .newsletter_quick_subscribe button.light:hover {
    background: #fff;
    border-color: #fff;
    color: #E2002A;
  }
  .newsletter_quick_subscribe button > span {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
            justify-content: center;
    min-height: 2.75rem;
  }
  .newsletter_quick_subscribe button .icon {
    fill: #fff;
    flex-shrink: 0;
    height: 1.5rem;
    -webkit-transition: fill 300ms;
    transition: fill 300ms;
    width: 1.5rem;
  }
  .newsletter_quick_subscribe button .text {
    font-size: 90%;
    font-weight: 700;
    padding: 0.45em 0;
    text-align: center;
  }
  .newsletter_quick_subscribe button .icon + .text {
    padding-left: 0.6em;
  }
  .newsletter_quick_subscribe button:focus {
    box-shadow: 0 0 0 0.1875rem rgba(226, 0, 42, 0.2);
    background: #ff1641;
    border-color: #ff1641;
    color: #fff;
  }
  .newsletter_quick_subscribe button:focus .icon {
    fill: #fff;
  }
  .toggle_offcanvas_menu {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-inline-box;
    display: inline-flex;
    margin-right: 2.7em;
    position: relative;
  }
  .toggle_offcanvas_menu:hover .text {
    color: #E2002A;
    -webkit-transition: color 300ms;
    transition: color 300ms;
  }
  .toggle_offcanvas_menu:hover .icon {
    fill: #E2002A;
    -webkit-transition: fill 300ms;
    transition: fill 300ms;
  }
  .toggle_offcanvas_menu label {
    -webkit-box-align: center;
            align-items: center;
    cursor: pointer;
    display: -webkit-box;
    display: flex;
    font-size: 112.5%;
    font-weight: 700;
    height: 2.75rem;
    position: relative;
    text-transform: uppercase;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    z-index: 10;
  }
  .toggle_offcanvas_menu label .icon {
    height: 1.875rem;
    margin-left: 0.9em;
    position: relative;
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
    -webkit-transition: fill 300ms;
    transition: fill 300ms;
    width: 1.625rem;
  }
  .toggle_offcanvas_menu label .text {
    color: #202020;
    display: none;
    -webkit-transition: color 300ms;
    transition: color 300ms;
  }
  .toggle_offcanvas_menu a {
    bottom: 0;
    cursor: pointer;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }
  .toggle_offcanvas_menu .open_offcanvas_menu {
    display: block;
  }
  .toggle_offcanvas_menu .close_offcanvas_menu {
    display: none;
  }
  .offcanvas_menu_overlay {
    -webkit-backdrop-filter: blur(0.125rem);
    background: rgba(32, 32, 32, 0.7);
    height: 100%;
    left: 0;
    opacity: 0;
    position: fixed;
    right: 0;
    top: 0;
    -webkit-transition: opacity 240ms, visibility 240ms;
    transition: opacity 240ms, visibility 240ms;
    visibility: hidden;
    z-index: 110;
  }
  .offcanvas_menu {
    background-color: #fff;
    box-shadow: 0 0 1.5rem rgba(0, 0, 0, 0.2);
    display: block;
    height: 100%;
    min-height: 100%;
    opacity: 0;
    overflow-x: hidden;
    overflow-y: scroll;
    padding: 1.8em 0;
    position: fixed;
    -webkit-transform: scaleX(0);
            transform: scaleX(0);
    -webkit-transition: opacity 240ms, visibility 240ms, -webkit-transform 240ms;
    transition: opacity 240ms, visibility 240ms, -webkit-transform 240ms;
    transition: opacity 240ms, transform 240ms, visibility 240ms;
    transition: opacity 240ms, transform 240ms, visibility 240ms, -webkit-transform 240ms;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    visibility: hidden;
    width: 15.625rem;
    z-index: 5000;
    right: 0;
    -webkit-transform-origin: right;
            transform-origin: right;
  }
  .offcanvas_menu .offcanvas_menu_content {
    position: absolute;
  }
  .offcanvas_menu .offcanvas_menu_content > ul > li {
    margin: 0.9em 0;
    padding: 0;
    position: relative;
  }
  .offcanvas_menu .offcanvas_menu_content > ul > li:last-child {
    border-bottom: 0;
  }
  .offcanvas_menu .offcanvas_menu_content > ul > li > a {
    border-radius: 0.25rem;
    color: #202020;
    display: block;
    font-size: 0.875rem;
    font-weight: 700;
    -webkit-hyphens: none;
        -ms-hyphens: none;
            hyphens: none;
    line-height: 3.125rem;
    margin: 0 1.8em;
    padding: 0 1.38461538em;
    position: relative;
    text-decoration: none;
    text-transform: uppercase;
    -webkit-transition: background-color 300ms, color 300ms;
    transition: background-color 300ms, color 300ms;
  }
  .offcanvas_menu .offcanvas_menu_content > ul > li > a:focus,
  .offcanvas_menu .offcanvas_menu_content > ul > li > a:hover,
  .offcanvas_menu .offcanvas_menu_content > ul > li > a.active {
    background-color: #E2002A;
    color: #fff;
  }
  .offcanvas_menu .offcanvas_menu_content > ul ul {
    height: 0;
    margin-bottom: 0;
    overflow: hidden;
    -webkit-transition: height 300ms;
    transition: height 300ms;
  }
  .offcanvas_menu .offcanvas_menu_content > ul ul:target {
    display: block;
  }
  .offcanvas_menu .offcanvas_menu_content > ul ul li {
    padding: 0;
  }
  .offcanvas_menu .offcanvas_menu_content > ul ul a {
    color: #202020;
    display: inline-block;
    font-size: 0.9375rem;
    -webkit-hyphens: none;
        -ms-hyphens: none;
            hyphens: none;
    padding: 0.36em 2.88em;
    text-decoration: none;
    -webkit-transition: background-color 300ms, color 300ms;
    transition: background-color 300ms, color 300ms;
    width: 100%;
  }
  .offcanvas_menu .offcanvas_menu_content > ul ul a:focus,
  .offcanvas_menu .offcanvas_menu_content > ul ul a:hover,
  .offcanvas_menu .offcanvas_menu_content > ul ul a.active {
    color: #E2002A;
  }
  .onlinetools_popup_overview .entry {
    margin: 0 0 0.9em;
  }
  .onlinetools_popup_overview .description {
    display: block;
    margin-bottom: 0.9em;
  }
  .onlinetools_popup_overview .touch_link .title {
    margin: 0 0 0.45em;
    padding: 0;
  }
  .popup_overlay {
    -webkit-backdrop-filter: blur(0.125rem);
    background: rgba(32, 32, 32, 0.7);
    bottom: 0;
    left: 0;
    opacity: 0;
    padding: 1% 0;
    position: fixed;
    right: 0;
    top: 0;
    -webkit-transition: opacity 300ms, visibility 300ms;
    transition: opacity 300ms, visibility 300ms;
    visibility: hidden;
    z-index: -1;
  }
  .popup_overlay.show {
    opacity: 1;
    visibility: visible;
    z-index: 9000;
  }
  .popup_header {
    padding: 0 0 0 1.8em;
  }
  .popup_close {
    background: #E2002A;
    border-radius: 0.25rem 0.25rem 0 0;
    float: right;
    height: 2.2rem;
    margin-right: 0.0625rem;
    padding: 0.51428571em;
    text-align: center;
    width: 2.75rem;
  }
  .popup_close .icon {
    fill: #fff;
    height: 1.5rem;
    width: 1.5rem;
  }
  .popup_content {
    height: 100%;
    margin: 0 auto;
    max-width: 100rem;
    opacity: 0;
    position: relative;
    -webkit-transform: scale(0.6);
            transform: scale(0.6);
    -webkit-transition: opacity 300ms, visibility 300ms, -webkit-transform 300ms;
    transition: opacity 300ms, visibility 300ms, -webkit-transform 300ms;
    transition: opacity 300ms, transform 300ms, visibility 300ms;
    transition: opacity 300ms, transform 300ms, visibility 300ms, -webkit-transform 300ms;
    visibility: hidden;
    z-index: 9500;
  }
  .popup_content.show {
    opacity: 1;
    -webkit-transform: scale(1);
            transform: scale(1);
    visibility: visible;
  }
  .popup_content .content {
    background: #fff;
    bottom: 0;
    box-shadow: 0 0 1.5625rem rgba(32, 32, 32, 0.7);
    left: 0;
    position: absolute;
    right: 0;
    top: 2.2rem;
  }
  .popup_iframe {
    background: #fff;
    border: 0;
    height: 100%;
    opacity: 0;
    position: relative;
    -webkit-transition: opacity 300ms;
    transition: opacity 300ms;
    width: 100%;
    z-index: 1;
  }
  .popup_iframe.show {
    opacity: 1;
  }
  .open_street_map .map {
    background-color: #d7d7d8;
    cursor: move;
    height: 21.875rem;
    position: relative;
    width: 100%;
  }
  .open_street_map .map_overlay {
    -webkit-box-align: center;
            align-items: center;
    background-color: rgba(215, 215, 216, 0.85);
    bottom: 0;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
            justify-content: center;
    left: 0;
    opacity: 0;
    pointer-events: none;
    position: absolute;
    right: 0;
    top: 0;
    -webkit-transition: opacity 300ms;
    transition: opacity 300ms;
    z-index: 1;
  }
  .open_street_map .map_overlay.show {
    opacity: 1;
    -webkit-transition-delay: 0;
            transition-delay: 0;
  }
  .open_street_map .map_overlay.show p {
    font-size: 120%;
    margin: 1.8em 3.6em;
    text-align: center;
  }
  .open_street_map .required_description {
    margin: 1.8em 0 0 0;
  }
  .open_street_map .form_disclaimer {
    margin: 1.8em 0 0 0;
  }
  .open_street_map .plan_route {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    flex-wrap: wrap;
    margin: 1.8em 0;
    position: relative;
  }
  .open_street_map .plan_route .saddr {
    -webkit-box-flex: 1;
            flex-grow: 1;
    flex-shrink: 1;
  }
  .open_street_map .plan_route .select_wrapper {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    -webkit-box-flex: 1;
            flex-grow: 1;
    flex-shrink: 1;
    margin-top: 0.9em;
  }
  .open_street_map .plan_route .form_disclaimer {
    margin-top: 1.8em;
  }
  .open_street_map .ol-viewport {
    touch-action: pan-y !important;
  }
  .open_street_map .ol-overlaycontainer-stopevent button {
    -webkit-box-align: center;
            align-items: center;
    background: #E2002A;
    border: 0.0625rem solid #E2002A;
    border-radius: 0.25rem;
    color: #fff;
    display: -webkit-inline-box;
    display: inline-flex;
    font-weight: 700;
    height: 2.75rem;
    -webkit-box-pack: center;
            justify-content: center;
    margin: 0;
    padding: 0;
    text-decoration: none;
    -webkit-transition: background 300ms, border 300ms, color 300ms;
    transition: background 300ms, border 300ms, color 300ms;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    width: 2.75rem;
  }
  .open_street_map .ol-overlaycontainer-stopevent button:focus {
    background: #ff1641;
    border: 0.0625rem solid #ff1641;
    box-shadow: 0 0 0 0.1875rem rgba(226, 0, 42, 0.2);
    color: #fff;
    outline: 0;
  }
  .open_street_map .ol-overlaycontainer-stopevent button:hover {
    background: #202020;
    border: 0.0625rem solid #202020;
    color: #fff;
  }
  .open_street_map .ol-overlaycontainer-stopevent button.disabled {
    cursor: default;
    opacity: 0.6;
  }
  .open_street_map .ol-overlaycontainer-stopevent .ol-attribution,
  .open_street_map .ol-overlaycontainer-stopevent .ol-zoom-in,
  .open_street_map .ol-overlaycontainer-stopevent .ol-zoom-out,
  .open_street_map .ol-overlaycontainer-stopevent .ol-zoomslider {
    position: absolute;
    right: 0.3125rem;
    z-index: 99;
  }
  .open_street_map .ol-overlaycontainer-stopevent .ol-zoom-in {
    line-height: 1;
    top: 0.3125rem;
  }
  .open_street_map .ol-overlaycontainer-stopevent .ol-zoom-out {
    line-height: 1;
    top: 3.375rem;
  }
  .open_street_map .ol-overlaycontainer-stopevent .ol-rotate-reset {
    display: none;
  }
  .open_street_map .ol-overlaycontainer-stopevent .ol-attribution {
    -webkit-box-align: center;
            align-items: center;
    bottom: 0;
    cursor: default;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: end;
            justify-content: flex-end;
    right: 0;
    text-align: right;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    z-index: 99;
  }
  .open_street_map .ol-overlaycontainer-stopevent .ol-attribution ul {
    -webkit-box-align: center;
            align-items: center;
    background: rgba(255, 255, 255, 0.85);
    color: black;
    display: -webkit-box;
    display: flex;
    height: 2.75rem;
    -webkit-box-pack: end;
            justify-content: flex-end;
    list-style: none !important;
    margin: 0;
    padding: 0;
    text-shadow: 0 0 0.125rem rgba(0, 0, 0, 0.15);
    -webkit-transform-origin: center right;
            transform-origin: center right;
    -webkit-transition: opacity 300ms, -webkit-transform 300ms;
    transition: opacity 300ms, -webkit-transform 300ms;
    transition: transform 300ms, opacity 300ms;
    transition: transform 300ms, opacity 300ms, -webkit-transform 300ms;
  }
  .open_street_map .ol-overlaycontainer-stopevent .ol-attribution ul li {
    -webkit-box-align: center;
            align-items: center;
    background-image: none !important;
    display: -webkit-box;
    display: flex;
    font-size: 85%;
    margin: 0 0.9em;
    padding: 0;
  }
  .open_street_map .ol-overlaycontainer-stopevent .ol-attribution ul li a {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-inline-box;
    display: inline-flex;
    margin: 0 0.3125rem;
  }
  .open_street_map .ol-overlaycontainer-stopevent .ol-attribution button {
    display: none;
  }
  .open_street_map.disable_ui .ol-overlaycontainer-stopevent .ol-zoom-in,
  .open_street_map.disable_ui .ol-overlaycontainer-stopevent .ol-zoom-out {
    display: none;
  }
  .open_street_map .form_disclaimer {
    background: #d7d7d8;
    border-radius: 0.25rem;
    color: #707173;
    margin-bottom: 0.9em;
    padding: 1.2em 1.8em;
  }
  .opening_times .title {
    padding-top: 0.9em;
  }
  .opening_times .entries {
    display: -webkit-box;
    display: flex;
    flex-wrap: wrap;
  }
  .opening_times .entry {
    margin-right: 2.7em;
  }
  .preloader {
    display: block;
    position: relative;
  }
  .preloader .icon_spin {
    -webkit-animation: preloader_rotate 900ms linear infinite;
            animation: preloader_rotate 900ms linear infinite;
    display: block;
    fill: #bdbebf;
    height: 3rem;
    left: 50%;
    margin: -1.5rem 0 0 -1.5rem;
    opacity: 1;
    position: absolute;
    top: 50%;
    -webkit-transition: 300ms;
    transition: 300ms;
    visibility: visible;
    width: 3rem;
  }
  .preloader .icon_spin.hide {
    opacity: 0;
    visibility: hidden;
  }
  .no-cssanimations .icon_spin {
    display: none;
  }
  form[role="search"] {
    display: -webkit-box;
    display: flex;
  }
  form[role="search"] button {
    border-radius: 0 0.25rem 0.25rem 0;
    margin: 0;
    min-width: 2.75rem;
    padding: 0;
    width: 2.75rem;
    background: #E2002A;
    border: 0.125rem solid #E2002A;
    color: #fff;
    cursor: pointer;
    display: -webkit-inline-box;
    display: inline-flex;
    -webkit-box-pack: center;
            justify-content: center;
    line-height: 1.8;
    margin-top: 1.8em;
    text-decoration: none;
    text-transform: uppercase;
    -webkit-transition: background 300ms, border 300ms, color 300ms;
    transition: background 300ms, border 300ms, color 300ms;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
  }
  form[role="search"] button.black {
    background: #202020;
    border-color: #202020;
  }
  form[role="search"] button.black:hover {
    background: #E2002A;
    border-color: #202020;
  }
  form[role="search"] button.light {
    background: transparent;
    border-color: #fff;
  }
  form[role="search"] button.light:hover {
    background: #fff;
    border-color: #fff;
    color: #E2002A;
  }
  form[role="search"] button > span {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
            justify-content: center;
    min-height: 2.75rem;
  }
  form[role="search"] button .icon {
    fill: #fff;
    flex-shrink: 0;
    height: 1.5rem;
    -webkit-transition: fill 300ms;
    transition: fill 300ms;
    width: 1.5rem;
  }
  form[role="search"] button .text {
    font-size: 90%;
    font-weight: 700;
    padding: 0.45em 0;
    text-align: center;
  }
  form[role="search"] button .icon + .text {
    padding-left: 0.6em;
  }
  form[role="search"] button[disabled] {
    background-color: #d7d7d8;
    border-color: #cacbcb;
    color: #f1f1f1;
  }
  form[role="search"] button[disabled] .icon {
    fill: #707173;
  }
  form[role="search"] button:focus {
    box-shadow: 0 0 0 0.1875rem rgba(226, 0, 42, 0.2);
    position: relative;
    z-index: 10;
    background: #ff1641;
    border-color: #ff1641;
    color: #fff;
  }
  form[role="search"] button:focus .icon {
    fill: #fff;
  }
  form[role="search"] button .icon {
    height: calc(2.75rem - (0.0625rem * 2));
  }
  input[type="search"] {
    -moz-appearance: none;
    -webkit-appearance: none;
    background: #fff;
    border: 0.0625rem solid #bdbebf;
    border-radius: 0.25rem 0 0 0.25rem;
    box-shadow: 0 0 0 #bdbebf inset;
    color: #707173;
    flex-basis: 100%;
    height: 2.75rem;
    margin: 0;
    padding: 0 0.9em;
    -webkit-transition: background 300ms, border-color 300ms, box-shadow 300ms, color 300ms, outline 300ms, z-index 300ms;
    transition: background 300ms, border-color 300ms, box-shadow 300ms, color 300ms, outline 300ms, z-index 300ms;
  }
  input[type="search"]:focus {
    background: #fff;
    border-color: #202020;
    box-shadow: 0 0 0.1875rem #202020 inset, 0 0 0 0.1875rem rgba(32, 32, 32, 0.2);
    color: #707173;
    outline: 0;
    position: relative;
    z-index: 10;
  }
  input[type="search"]::-webkit-search-cancel-button,
  input[type="search"]::-webkit-search-decoration,
  input[type="search"]::-webkit-search-results-button,
  input[type="search"]::-webkit-search-results-decoration {
    -webkit-appearance: none;
  }
  .search dl {
    margin: 0 0 0.9em;
    padding-top: 0.45em;
  }
  .search dl br,
  .search dl em,
  .search dl img {
    display: none;
  }
  .search dd {
    margin: 0;
  }
  .toggle_search_box {
    -webkit-box-align: center;
            align-items: center;
    align-self: center;
    display: -webkit-box;
    display: flex;
    height: 2.125rem;
    -webkit-box-pack: center;
            justify-content: center;
    position: relative;
    width: 2.125rem;
  }
  .toggle_search_box label {
    bottom: 0;
    cursor: pointer;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 10;
  }
  .toggle_search_box label:hover ~ a {
    fill: #E2002A;
  }
  .toggle_search_box a {
    fill: #202020;
  }
  .toggle_search_box a .icon {
    display: block;
    height: 1.5rem;
    -webkit-transition: fill 300ms;
    transition: fill 300ms;
    width: 1.5rem;
  }
  .toggle_search_box .open_search_box {
    display: block;
  }
  .toggle_search_box .close_search_box {
    display: none;
  }
  .search_box {
    -webkit-backdrop-filter: blur(0.125rem);
    -webkit-box-align: center;
            align-items: center;
    bottom: 0;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
            justify-content: center;
    left: 0;
    opacity: 0;
    padding: 0.9em;
    position: fixed;
    right: 0;
    top: 0;
    -webkit-transition: opacity 300ms;
    transition: opacity 300ms;
    visibility: hidden;
    z-index: 9999;
  }
  .search_box .close_search_box_wrapper {
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }
  .search_box .close_search_box_wrapper label {
    background: rgba(32, 32, 32, 0.7);
    bottom: 0;
    left: 0;
    opacity: 0;
    position: absolute;
    right: 0;
    top: 0;
    -webkit-transition: opacity 300ms, visibility 300ms;
    transition: opacity 300ms, visibility 300ms;
    visibility: hidden;
  }
  .search_box .close_search_box_wrapper .close_search_box {
    align-content: center;
    -webkit-box-align: center;
            align-items: center;
    background: #E2002A;
    border: 0.0625rem solid #E2002A;
    border-radius: 50%;
    box-shadow: 0 0 1.25rem rgba(112, 113, 115, 0.7);
    display: -webkit-box;
    display: flex;
    height: 2.75rem;
    -webkit-box-pack: center;
            justify-content: center;
    padding: 0.1875rem;
    position: absolute;
    right: 1.8em;
    top: 1.8em;
    width: 2.75rem;
  }
  .search_box .close_search_box_wrapper .close_search_box:focus,
  .search_box .close_search_box_wrapper .close_search_box:hover {
    background-color: #ff1641;
    outline: 0;
  }
  .search_box .close_search_box_wrapper .close_search_box .icon {
    fill: #fff;
    height: 1.875rem;
    width: 1.875rem;
  }
  .search_box form[role="search"] {
    background: #fff;
    border: 0.625rem solid #fff;
    border-radius: 0.25rem;
    box-shadow: 0 0 1.25rem rgba(112, 113, 115, 0.5);
    display: -webkit-box;
    display: flex;
    opacity: 0;
    position: relative;
    -webkit-transform: translateY(-6.25rem);
            transform: translateY(-6.25rem);
    -webkit-transition: none;
    transition: none;
    visibility: hidden;
    width: 37.5rem;
    z-index: 1000;
  }
  .search_box form[role="search"] button {
    border-width: 0;
    -webkit-box-flex: 0;
            flex: 0 1 3.75rem;
    height: auto;
    margin: 0;
    padding: 0 0.45em;
  }
  .search_box input[type="search"] {
    -webkit-box-flex: 1;
            flex: 1 1 auto;
    font-size: 125%;
  }
  .search_box_state {
    left: 0;
    opacity: 0;
    position: fixed;
    top: 0;
    visibility: hidden;
  }
  .search_box_state:checked ~ .search_box,
  .search_box_state:target ~ .search_box {
    opacity: 1;
    visibility: visible;
  }
  .search_box_state:checked ~ .search_box .close_search_box_wrapper label,
  .search_box_state:target ~ .search_box .close_search_box_wrapper label {
    opacity: 1;
    visibility: visible;
  }
  .search_box_state:checked ~ .search_box form[role="search"],
  .search_box_state:target ~ .search_box form[role="search"] {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
    -webkit-transition: -webkit-transform 300ms;
    transition: -webkit-transform 300ms;
    transition: transform 300ms;
    transition: transform 300ms, -webkit-transform 300ms;
    visibility: visible;
  }
  .search_box_state:checked ~ .offcanvas_menu_wrapper .toggle_search_box .open_search_box,
  .search_box_state:target ~ .offcanvas_menu_wrapper .toggle_search_box .open_search_box {
    display: none;
  }
  .search_box_state:checked ~ .offcanvas_menu_wrapper .toggle_search_box .close_search_box,
  .search_box_state:target ~ .offcanvas_menu_wrapper .toggle_search_box .close_search_box {
    display: block;
  }
  .social_media_legal_notice {
    padding: 1.8em;
  }
  .social_media_legal_notice .company_logo {
    margin: 0 auto;
    max-width: 25rem;
    text-align: center;
  }
  .social_media_links ul {
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: end;
            justify-content: flex-end;
    margin: 0;
    padding: 0;
  }
  .social_media_links li {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  .social_media_links a {
    border-radius: 0.25rem;
    display: inline-block;
  }
  .social_media_links a .icon {
    display: block;
    fill: #fff;
    height: 2.125rem;
    width: 2.125rem;
  }
  .social_media_links a.video {
    background: #E2002A;
  }
  .social_media_share ul {
    display: -webkit-box;
    display: flex;
    flex-wrap: wrap;
    margin: 1.8em 0 0.9em 0;
    padding: 0;
  }
  .social_media_share li {
    list-style: none;
    margin: 0.45em 0;
    padding: 0;
  }
  .social_media_share a {
    color: #707173;
    cursor: pointer;
    display: block;
    margin-right: 0.9em;
    padding-right: 0.9em;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
  }
  .social_media_share a > span {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
    height: 2.75rem;
  }
  .social_media_share a .icon {
    border-radius: 0.25rem;
    height: 2.125rem;
    width: 2.125rem;
  }
  .social_media_share a .text {
    font-weight: 400;
    padding-left: 0.9em;
  }
  .social_media_share a.print {
    display: none;
  }
  .social_media_share a.print .icon {
    background: #E2002A;
    text-align: center;
  }
  .social_media_share a.print .icon svg {
    fill: #fff;
    height: 2.125rem;
    width: 1.5rem;
  }
  .steuernews_article_slider {
    display: block;
    margin-right: calc(-49.8vw + 50%);
    position: relative;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
  }
  .steuernews_article_slider .linked_folder_content {
    margin: 0 auto;
    overflow: hidden;
    width: 100%;
  }
  .steuernews_article_slider .wrapper_inner {
    align-content: flex-start;
    -webkit-box-align: start;
            align-items: flex-start;
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
            flex-direction: row;
    flex-wrap: nowrap;
    -webkit-box-pack: start;
            justify-content: flex-start;
    margin-right: 1.8em;
    -webkit-transition: -webkit-transform 900ms;
    transition: -webkit-transform 900ms;
    transition: transform 900ms;
    transition: transform 900ms, -webkit-transform 900ms;
    width: 16.25rem;
  }
  .steuernews_article_slider .slide {
    align-content: flex-start;
    background-position: center top !important;
    background-size: cover !important;
    border: 0.0625rem solid #dadadb;
    border-radius: 0.25rem;
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    flex-shrink: 0;
    flex-wrap: nowrap;
    height: 23.75rem;
    -webkit-box-pack: start;
            justify-content: flex-start;
    margin: 0 1.8em 0 0;
    width: 100%;
  }
  .steuernews_article_slider .slide .description {
    align-self: auto;
    background: rgba(32, 32, 32, 0.8);
    border-bottom-left-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
    color: #fff;
    display: -webkit-box;
    display: flex;
    -webkit-box-flex: 1;
            flex: 1 1 auto;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    padding: 1.8em;
    width: 100%;
  }
  .steuernews_article_slider .slide .description .title {
    color: #fff;
    font-size: 110%;
    margin: 0 0 0.45em 0;
  }
  .steuernews_article_slider .slide .description p {
    align-self: auto;
    -webkit-box-flex: 1;
            flex: 1 1 auto;
  }
  .steuernews_article_slider .slide .description .links {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
  }
  .steuernews_article_slider .slide .description .links .link {
    -webkit-box-align: center;
            align-items: center;
    color: #fff;
    display: -webkit-box;
    display: flex;
    font-size: 90%;
    font-weight: 500;
    margin-bottom: 0.9em;
    text-decoration: none;
  }
  .steuernews_article_slider .slide .description .links .link span {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
  }
  .steuernews_article_slider .slide .description .links .link:hover {
    color: #E2002A;
  }
  .steuernews_article_slider .slide .description .links .link:first-child {
    margin-right: 1.2em;
  }
  .steuernews_article_slider .slide .description .links .icon {
    display: inline-block;
    fill: #fff;
    height: 1.125rem;
    margin-right: 0.9em;
    width: 1.125rem;
  }
  .steuernews_article_slider .slide .description .sub_title {
    display: block;
    font-size: 80%;
    margin-bottom: 0.9em;
  }
  .steuernews_article_slider .control_slides {
    background-color: rgba(32, 32, 32, 0.8);
    border-radius: 0.25rem;
    display: block;
    fill: #fff;
    height: 2.375rem;
    margin-top: -5.4em;
    padding: 0.5em;
    position: absolute;
    right: 0;
    top: 0;
    width: 2.375rem;
    z-index: 15;
  }
  .steuernews_article_slider .control_slides:focus,
  .steuernews_article_slider .control_slides:hover {
    background-color: rgba(226, 0, 42, 0.8);
    outline: 0;
  }
  .steuernews_article_slider .prev_slide {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
    right: 5rem;
  }
  .steuernews_article_slider .next_slide {
    right: 2.16em;
  }
  .steuernews_navigation {
    margin: 1.8em 0;
  }
  .steuernews_navigation nav ul:after {
    clear: both;
  }
  .steuernews_navigation nav ul:after,
  .steuernews_navigation nav ul:before {
    content: "";
    display: table;
  }
  .steuernews_navigation nav li {
    float: left;
  }
  .steuernews_navigation nav li:first-child a {
    padding-left: 0;
  }
  .steuernews_navigation nav a {
    display: inline-block;
    line-height: 1.8;
    padding: 0 1.8em;
  }
  .steuernews_content [href*="thema"] {
    color: #707173;
  }
  .steuernews_preview {
    margin-bottom: 0.9em;
  }
  .steuernews_preview .entry {
    align-content: flex-start;
    background-position: center top !important;
    background-size: cover !important;
    border: 0.0625rem solid #dadadb;
    border-radius: 0.25rem;
    color: #fff;
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    flex-shrink: 0;
    flex-wrap: nowrap;
    -webkit-box-pack: start;
            justify-content: flex-start;
    margin: 0 0 1.8em 0;
    min-height: 18.75rem;
    -webkit-transition: all 150ms;
    transition: all 150ms;
    width: 100%;
  }
  .steuernews_preview .entry:hover {
    border-radius: 0.25rem;
    box-shadow: 0rem 0.125rem 0.9375rem 0rem rgba(0, 0, 0, 0.1);
    color: #fff;
    -webkit-transform: scale(0.95);
            transform: scale(0.95);
    -webkit-transition: all 150ms;
    transition: all 150ms;
  }
  .steuernews_preview .entry:hover .title {
    color: #E2002A;
    text-decoration: none;
  }
  .steuernews_preview .entry .title {
    color: #fff;
    text-decoration: none;
  }
  .steuernews_preview .description {
    align-self: auto;
    background: rgba(32, 32, 32, 0.8);
    border-bottom-left-radius: 0.25rem;
    border-top-left-radius: 0.25rem;
    display: -webkit-box;
    display: flex;
    flex-basis: auto;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    -webkit-box-flex: 1;
            flex-grow: 1;
    flex-shrink: 1;
    margin: -0.0625rem;
    padding: 1.8em;
    width: 100%;
  }
  .steuernews_preview .description .title {
    font-size: 110%;
    margin: 0 0 0.45em 0;
  }
  .steuernews_preview .image {
    -webkit-box-flex: 0;
            flex: 0 0 auto;
    margin: 0;
    -webkit-box-ordinal-group: 2;
            order: 1;
  }
  .steuernews_preview .image img {
    margin-right: 1.8em;
  }
  .steuernews_preview .teaser_text {
    -webkit-box-ordinal-group: 3;
            order: 2;
  }
  .steuernews_preview .video_play {
    position: relative;
  }
  .steuernews_preview .video_play .icon {
    fill: #E2002A;
    height: 2.75rem;
    left: 0;
    margin: -1.375rem 0 0 0;
    position: absolute;
    right: 0;
    text-align: center;
    top: 50%;
  }
  .steuernews_search {
    margin: 0 0 1.8em;
  }
  .steuernews_search .search {
    margin: 0 0 0.9em;
  }
  .news_entity .hide_creation_date {
    display: none;
  }
  .news_image {
    margin-left: 0;
  }
  .news_image + * {
    padding-top: 0;
  }
  .steuernews_video_overview {
    margin-bottom: 1.8em;
  }
  .steuernews_video_overview .entry {
    display: inline-block;
  }
  .steuernews_video_overview .image {
    margin-bottom: 0.9em;
    position: relative;
  }
  .steuernews_video_overview .video_play {
    position: relative;
  }
  .steuernews_video_overview .video_play .icon {
    fill: #E2002A;
    height: 2.75rem;
    left: 0;
    margin: -1.375rem 0 0 0;
    position: absolute;
    right: 0;
    text-align: center;
    top: 50%;
  }
  .steuernews_contact_person {
    background: #d7d7d8;
    border-radius: 0.25rem;
    margin-bottom: 1.8em;
    padding: 1.8em;
  }
  .steuernews_contact_person .team_entry {
    display: -webkit-box;
    display: flex;
  }
  .steuernews_contact_person .team_entry figure {
    display: block;
  }
  .steuernews_contact_person .contact_person_entry {
    height: auto;
    margin-top: 1.8em;
    position: relative;
  }
  .steuernews_contact_person .more_team_contacts {
    background: #fff;
    border-radius: 0.25rem;
    color: #E2002A;
    display: block;
    line-height: 1.8;
    margin: 1em 0;
    padding: 0.9em;
    text-align: center;
  }
  .steuernews_newsletter_subscribe {
    background: #d7d7d8;
    border-radius: 0.25rem;
    margin-bottom: 1.8em;
    padding: 1.8em;
  }
  .steuernews_newsletter_subscribe .disclaimer {
    background-color: #fff;
  }
  .steuernews_sidebar {
    margin: 1.8em 0 0.9em;
  }
  .steuernews_current_issue {
    margin-bottom: 0.9em;
    margin-top: 1.8em;
  }
  .steuernews_current_issue ul {
    list-style: url("../images/list_style_image_rectangle.svg");
    margin: 0 0 0.9em;
    padding-left: 1.35em;
  }
  .steuernews_current_issue ul > li {
    padding-left: 0.45em;
  }
  .steuernews_current_issue ul ol,
  .steuernews_current_issue ul ul {
    margin: 0;
  }
  .highlight_keyword {
    background: #E2002A;
    color: #fff;
    outline: 0.125rem solid #E2002A;
  }
  .team_group_overview h2 {
    font-size: 1.25rem;
    margin: 1.8em 0 0.9em;
  }
  .team_group_overview .overview_title {
    letter-spacing: calc((1.1875rem + -0.1875
                                 * (100vw - 17.5rem)
                                 / (93.75 - 17.5))
                                 / 1000 * 0);
    font-size: 1.1875rem;
  }
  .team_title h3 {
    letter-spacing: calc((1.25rem + -0.125
                                 * (100vw - 17.5rem)
                                 / (93.75 - 17.5))
                                 / 1000 * 0);
    font-size: 1.25rem;
  }
  .team_section_title .entry .title {
    text-transform: none;
    letter-spacing: calc((1.1875rem + -0.1875
                                 * (100vw - 17.5rem)
                                 / (93.75 - 17.5))
                                 / 1000 * 0);
    font-size: 1.1875rem;
  }
  .team_overview {
    margin-bottom: 1.8em;
  }
  .team_overview .entry {
    -webkit-box-align: center;
            align-items: center;
    background: #F3F1EF;
    border-radius: 0.25rem;
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
            flex-direction: row;
    -webkit-box-flex: 1;
            flex-grow: 1;
    margin-bottom: 3.6em;
    margin-right: 3.6em;
    margin-top: 7.8125rem;
    position: relative;
  }
  .team_overview .title {
    margin: 0;
    padding: 0;
  }
  .team_overview .photo {
    border-radius: 0.25rem;
    -webkit-box-flex: 0;
            flex: 0 0 auto;
    height: 9.375rem;
    left: 0;
    margin: 0;
    position: absolute;
    right: 0;
    top: -7.8125rem;
  }
  .team_overview .photo img {
    border-radius: 0.25rem;
    display: block;
    height: 9.375rem;
    margin-left: 2.7em;
  }
  .team_overview .info {
    border-radius: 0.25rem;
    padding: 2.7em 5.4em 2.7em 2.7em;
  }
  .team_overview .info .contact {
    display: block;
  }
  .team_overview .info .contact strong {
    margin-right: 0.225em;
  }
  .team_overview .info .buttons {
    margin: -1.8em 0 0;
  }
  .team_overview .info .buttons .button {
    margin-top: 0.45em;
  }
  .team_overview .info .buttons .call {
    margin-top: 1.8em;
  }
  .teaser .title {
    color: #E2002A !important;
    font-weight: 500;
  }
  .keyword_register {
    margin-bottom: 0.9em;
  }
  .keyword_register:after {
    clear: both;
  }
  .keyword_register:after,
  .keyword_register:before {
    content: "";
    display: table;
  }
  .keyword_register a {
    background: #d7d7d8;
    float: left;
    line-height: 2.75rem;
    margin: 0 0.9em 0.9em 0;
    text-align: center;
    width: 2.75rem;
  }
  .steuernews_keywords ul,
  .steuernews_issues ul {
    display: -webkit-box;
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    padding-left: 0;
  }
  .steuernews_keywords ul li,
  .steuernews_issues ul li {
    padding-left: 0;
  }
  .steuernews_keywords a,
  .steuernews_issues a {
    background: #d7d7d8;
    border-radius: 0.25rem;
    display: inline-block;
    line-height: 2.75rem;
    margin: 0 0.9em 0.9em 0;
    padding: 0 1.8em;
    white-space: nowrap;
  }
  .steuernews_keywords_register .keywords_register_entries a {
    display: inline-block;
    max-width: 12.5rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .tooltip_wrapper {
    display: block;
    position: relative;
  }
  [role="tooltip"] {
    background: #E2002A;
    border: 0.0625rem solid #E2002A;
    border-radius: 0.25rem;
    color: #fff;
    font-size: 85%;
    font-weight: 400;
    line-height: 2.0625rem;
    padding: 0 0.9em;
    position: absolute;
    right: 0;
    text-align: center;
    top: -2.4375rem;
    z-index: 50;
  }
  [role="tooltip"]:after {
    border: 0.625rem solid transparent;
    border-top-color: #E2002A;
    bottom: -1.25rem;
    content: "";
    display: block;
    height: 0;
    left: 50%;
    margin-left: -0.625rem;
    position: absolute;
    width: 0;
  }
  [role="tooltip"]:before {
    border: 0.6875rem solid transparent;
    border-top-color: #E2002A;
    bottom: -1.375rem;
    content: "";
    display: block;
    height: 0;
    left: 50%;
    margin-left: -0.6875rem;
    position: absolute;
    width: 0;
  }
  [role="tooltip"][aria-hidden="true"] {
    display: none;
  }
}
@media screen and (min-width: 75rem) {
  .aside {
    margin-top: 7.2em;
  }
}
@media screen and (min-width: 17.5rem) {
  .company_logo {
    max-width: calc(6.25rem + 3.125
                            * (100vw - 17.5rem)
                            / (93.75 - 17.5));
  }
}
@media screen and (min-width: 93.75rem) {
  .company_logo {
    max-width: 9.375rem;
  }
}
@media screen and (min-width: 61.875rem) {
  .dropdown_menu {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
    -webkit-box-flex: 1;
            flex-grow: 1;
  }
}
@media screen and (min-width: 47.5rem) {
  .footer_socialmedia .social_media_links .facebook {
    display: -webkit-box;
    display: flex;
  }
}
@media screen and (min-width: 35rem) {
  .formular .button_wrapper {
    text-align: right;
  }
}
@media screen and (min-width: 35rem) {
  .google_maps .step:before {
    font-size: inherit;
  }
}
@media screen and (min-width: 35rem) {
  .google_maps .instructions,
  .google_maps .distance {
    font-size: inherit;
  }
}
@media screen and (min-width: 17.5rem) {
  .google_maps .infowindow {
    max-width: calc(13.75rem + 8.75
                            * (100vw - 17.5rem)
                            / (93.75 - 17.5));
  }
}
@media screen and (min-width: 93.75rem) {
  .google_maps .infowindow {
    max-width: 22.5rem;
  }
}
@media screen and (min-width: 17.5rem) {
  .container {
    padding: 0 calc(0.9375rem + 2.1875
                                     * (100vw - 17.5rem)
                                     / (93.75 - 17.5));
  }
}
@media screen and (min-width: 93.75rem) {
  .container {
    padding: 0 3.125rem;
  }
}
@media screen and (min-width: 17.5rem) {
  .row {
    margin: 0 calc((-0.9375rem / 2) - (2.1875 / 2)
                                     * (100vw - 17.5rem)
                                     / (93.75 - 17.5));
  }
}
@media screen and (min-width: 93.75rem) {
  .row {
    margin: 0 -1.5625rem;
  }
}
@media screen and (min-width: 17.5rem) {
  .row.half_column_margin {
    margin: 0 calc((-0.9375rem / 4) - (2.1875 / 4)
                                     * (100vw - 17.5rem)
                                     / (93.75 - 17.5));
  }
}
@media screen and (min-width: 93.75rem) {
  .row.half_column_margin {
    margin: 0 -0.78125rem;
  }
}
@media screen and (min-width: 17.5rem) {
  .row.half_column_margin > [class*="span"] {
    padding: 0 calc((0.9375rem / 4) + (2.1875 / 4)
                                     * (100vw - 17.5rem)
                                     / (93.75 - 17.5));
  }
}
@media screen and (min-width: 93.75rem) {
  .row.half_column_margin > [class*="span"] {
    padding: 0 0.78125rem;
  }
}
@media screen and (min-width: 30rem) {
  .row.around_small {
    justify-content: space-around;
  }
  .row.between_small {
    -webkit-box-pack: justify;
            justify-content: space-between;
  }
  .row.start_small {
    -webkit-box-pack: start;
            justify-content: flex-start;
  }
  .row.center_small {
    -webkit-box-pack: center;
            justify-content: center;
  }
  .row.end_small {
    -webkit-box-pack: end;
            justify-content: flex-end;
  }
  .row.top_small {
    -webkit-box-align: start;
            align-items: flex-start;
  }
  .row.middle_small {
    -webkit-box-align: center;
            align-items: center;
  }
  .row.bottom_small {
    -webkit-box-align: end;
            align-items: flex-end;
  }
  .row.baseline_small {
    -webkit-box-align: baseline;
            align-items: baseline;
  }
}
@media screen and (min-width: 35rem) {
  .row.around_medium {
    justify-content: space-around;
  }
  .row.between_medium {
    -webkit-box-pack: justify;
            justify-content: space-between;
  }
  .row.start_medium {
    -webkit-box-pack: start;
            justify-content: flex-start;
  }
  .row.center_medium {
    -webkit-box-pack: center;
            justify-content: center;
  }
  .row.end_medium {
    -webkit-box-pack: end;
            justify-content: flex-end;
  }
  .row.top_medium {
    -webkit-box-align: start;
            align-items: flex-start;
  }
  .row.middle_medium {
    -webkit-box-align: center;
            align-items: center;
  }
  .row.bottom_medium {
    -webkit-box-align: end;
            align-items: flex-end;
  }
  .row.baseline_medium {
    -webkit-box-align: baseline;
            align-items: baseline;
  }
}
@media screen and (min-width: 47.5rem) {
  .row.around_large {
    justify-content: space-around;
  }
  .row.between_large {
    -webkit-box-pack: justify;
            justify-content: space-between;
  }
  .row.start_large {
    -webkit-box-pack: start;
            justify-content: flex-start;
  }
  .row.center_large {
    -webkit-box-pack: center;
            justify-content: center;
  }
  .row.end_large {
    -webkit-box-pack: end;
            justify-content: flex-end;
  }
  .row.top_large {
    -webkit-box-align: start;
            align-items: flex-start;
  }
  .row.middle_large {
    -webkit-box-align: center;
            align-items: center;
  }
  .row.bottom_large {
    -webkit-box-align: end;
            align-items: flex-end;
  }
  .row.baseline_large {
    -webkit-box-align: baseline;
            align-items: baseline;
  }
}
@media screen and (min-width: 61.875rem) {
  .row.around_xlarge {
    justify-content: space-around;
  }
  .row.between_xlarge {
    -webkit-box-pack: justify;
            justify-content: space-between;
  }
  .row.start_xlarge {
    -webkit-box-pack: start;
            justify-content: flex-start;
  }
  .row.center_xlarge {
    -webkit-box-pack: center;
            justify-content: center;
  }
  .row.end_xlarge {
    -webkit-box-pack: end;
            justify-content: flex-end;
  }
  .row.top_xlarge {
    -webkit-box-align: start;
            align-items: flex-start;
  }
  .row.middle_xlarge {
    -webkit-box-align: center;
            align-items: center;
  }
  .row.bottom_xlarge {
    -webkit-box-align: end;
            align-items: flex-end;
  }
  .row.baseline_xlarge {
    -webkit-box-align: baseline;
            align-items: baseline;
  }
}
@media screen and (min-width: 75rem) {
  .row.around_xxlarge {
    justify-content: space-around;
  }
  .row.between_xxlarge {
    -webkit-box-pack: justify;
            justify-content: space-between;
  }
  .row.start_xxlarge {
    -webkit-box-pack: start;
            justify-content: flex-start;
  }
  .row.center_xxlarge {
    -webkit-box-pack: center;
            justify-content: center;
  }
  .row.end_xxlarge {
    -webkit-box-pack: end;
            justify-content: flex-end;
  }
  .row.top_xxlarge {
    -webkit-box-align: start;
            align-items: flex-start;
  }
  .row.middle_xxlarge {
    -webkit-box-align: center;
            align-items: center;
  }
  .row.bottom_xxlarge {
    -webkit-box-align: end;
            align-items: flex-end;
  }
  .row.baseline_xxlarge {
    -webkit-box-align: baseline;
            align-items: baseline;
  }
}
@media screen and (min-width: 93.75rem) {
  .row.around_xxxlarge {
    justify-content: space-around;
  }
  .row.between_xxxlarge {
    -webkit-box-pack: justify;
            justify-content: space-between;
  }
  .row.start_xxxlarge {
    -webkit-box-pack: start;
            justify-content: flex-start;
  }
  .row.center_xxxlarge {
    -webkit-box-pack: center;
            justify-content: center;
  }
  .row.end_xxxlarge {
    -webkit-box-pack: end;
            justify-content: flex-end;
  }
  .row.top_xxxlarge {
    -webkit-box-align: start;
            align-items: flex-start;
  }
  .row.middle_xxxlarge {
    -webkit-box-align: center;
            align-items: center;
  }
  .row.bottom_xxxlarge {
    -webkit-box-align: end;
            align-items: flex-end;
  }
  .row.baseline_xxxlarge {
    -webkit-box-align: baseline;
            align-items: baseline;
  }
}
@media screen and (min-width: 17.5rem) {
  [class*="span"] {
    padding: 0 calc((0.9375rem / 2) + (2.1875 / 2)
                                     * (100vw - 17.5rem)
                                     / (93.75 - 17.5));
  }
}
@media screen and (min-width: 93.75rem) {
  [class*="span"] {
    padding: 0 1.5625rem;
  }
}
@media screen and (min-width: 30rem) {
  .span1_small {
    flex-basis: 8.33333333%;
    max-width: 8.33333333%;
  }
  .order1_small {
    -webkit-box-ordinal-group: 2;
            order: 1;
  }
  .span2_small {
    flex-basis: 16.66666667%;
    max-width: 16.66666667%;
  }
  .order2_small {
    -webkit-box-ordinal-group: 3;
            order: 2;
  }
  .span3_small {
    flex-basis: 25%;
    max-width: 25%;
  }
  .order3_small {
    -webkit-box-ordinal-group: 4;
            order: 3;
  }
  .span4_small {
    flex-basis: 33.33333333%;
    max-width: 33.33333333%;
  }
  .order4_small {
    -webkit-box-ordinal-group: 5;
            order: 4;
  }
  .span5_small {
    flex-basis: 41.66666667%;
    max-width: 41.66666667%;
  }
  .order5_small {
    -webkit-box-ordinal-group: 6;
            order: 5;
  }
  .span6_small {
    flex-basis: 50%;
    max-width: 50%;
  }
  .order6_small {
    -webkit-box-ordinal-group: 7;
            order: 6;
  }
  .span7_small {
    flex-basis: 58.33333333%;
    max-width: 58.33333333%;
  }
  .order7_small {
    -webkit-box-ordinal-group: 8;
            order: 7;
  }
  .span8_small {
    flex-basis: 66.66666667%;
    max-width: 66.66666667%;
  }
  .order8_small {
    -webkit-box-ordinal-group: 9;
            order: 8;
  }
  .span9_small {
    flex-basis: 75%;
    max-width: 75%;
  }
  .order9_small {
    -webkit-box-ordinal-group: 10;
            order: 9;
  }
  .span10_small {
    flex-basis: 83.33333333%;
    max-width: 83.33333333%;
  }
  .order10_small {
    -webkit-box-ordinal-group: 11;
            order: 10;
  }
  .span11_small {
    flex-basis: 91.66666667%;
    max-width: 91.66666667%;
  }
  .order11_small {
    -webkit-box-ordinal-group: 12;
            order: 11;
  }
  .span12_small {
    flex-basis: 100%;
    max-width: 100%;
  }
  .order12_small {
    -webkit-box-ordinal-group: 13;
            order: 12;
  }
}
@media screen and (min-width: 35rem) {
  .span1_medium {
    flex-basis: 8.33333333%;
    max-width: 8.33333333%;
  }
  .order1_medium {
    -webkit-box-ordinal-group: 2;
            order: 1;
  }
  .span2_medium {
    flex-basis: 16.66666667%;
    max-width: 16.66666667%;
  }
  .order2_medium {
    -webkit-box-ordinal-group: 3;
            order: 2;
  }
  .span3_medium {
    flex-basis: 25%;
    max-width: 25%;
  }
  .order3_medium {
    -webkit-box-ordinal-group: 4;
            order: 3;
  }
  .span4_medium {
    flex-basis: 33.33333333%;
    max-width: 33.33333333%;
  }
  .order4_medium {
    -webkit-box-ordinal-group: 5;
            order: 4;
  }
  .span5_medium {
    flex-basis: 41.66666667%;
    max-width: 41.66666667%;
  }
  .order5_medium {
    -webkit-box-ordinal-group: 6;
            order: 5;
  }
  .span6_medium {
    flex-basis: 50%;
    max-width: 50%;
  }
  .order6_medium {
    -webkit-box-ordinal-group: 7;
            order: 6;
  }
  .span7_medium {
    flex-basis: 58.33333333%;
    max-width: 58.33333333%;
  }
  .order7_medium {
    -webkit-box-ordinal-group: 8;
            order: 7;
  }
  .span8_medium {
    flex-basis: 66.66666667%;
    max-width: 66.66666667%;
  }
  .order8_medium {
    -webkit-box-ordinal-group: 9;
            order: 8;
  }
  .span9_medium {
    flex-basis: 75%;
    max-width: 75%;
  }
  .order9_medium {
    -webkit-box-ordinal-group: 10;
            order: 9;
  }
  .span10_medium {
    flex-basis: 83.33333333%;
    max-width: 83.33333333%;
  }
  .order10_medium {
    -webkit-box-ordinal-group: 11;
            order: 10;
  }
  .span11_medium {
    flex-basis: 91.66666667%;
    max-width: 91.66666667%;
  }
  .order11_medium {
    -webkit-box-ordinal-group: 12;
            order: 11;
  }
  .span12_medium {
    flex-basis: 100%;
    max-width: 100%;
  }
  .order12_medium {
    -webkit-box-ordinal-group: 13;
            order: 12;
  }
}
@media screen and (min-width: 47.5rem) {
  .span1_large {
    flex-basis: 8.33333333%;
    max-width: 8.33333333%;
  }
  .order1_large {
    -webkit-box-ordinal-group: 2;
            order: 1;
  }
  .span2_large {
    flex-basis: 16.66666667%;
    max-width: 16.66666667%;
  }
  .order2_large {
    -webkit-box-ordinal-group: 3;
            order: 2;
  }
  .span3_large {
    flex-basis: 25%;
    max-width: 25%;
  }
  .order3_large {
    -webkit-box-ordinal-group: 4;
            order: 3;
  }
  .span4_large {
    flex-basis: 33.33333333%;
    max-width: 33.33333333%;
  }
  .order4_large {
    -webkit-box-ordinal-group: 5;
            order: 4;
  }
  .span5_large {
    flex-basis: 41.66666667%;
    max-width: 41.66666667%;
  }
  .order5_large {
    -webkit-box-ordinal-group: 6;
            order: 5;
  }
  .span6_large {
    flex-basis: 50%;
    max-width: 50%;
  }
  .order6_large {
    -webkit-box-ordinal-group: 7;
            order: 6;
  }
  .span7_large {
    flex-basis: 58.33333333%;
    max-width: 58.33333333%;
  }
  .order7_large {
    -webkit-box-ordinal-group: 8;
            order: 7;
  }
  .span8_large {
    flex-basis: 66.66666667%;
    max-width: 66.66666667%;
  }
  .order8_large {
    -webkit-box-ordinal-group: 9;
            order: 8;
  }
  .span9_large {
    flex-basis: 75%;
    max-width: 75%;
  }
  .order9_large {
    -webkit-box-ordinal-group: 10;
            order: 9;
  }
  .span10_large {
    flex-basis: 83.33333333%;
    max-width: 83.33333333%;
  }
  .order10_large {
    -webkit-box-ordinal-group: 11;
            order: 10;
  }
  .span11_large {
    flex-basis: 91.66666667%;
    max-width: 91.66666667%;
  }
  .order11_large {
    -webkit-box-ordinal-group: 12;
            order: 11;
  }
  .span12_large {
    flex-basis: 100%;
    max-width: 100%;
  }
  .order12_large {
    -webkit-box-ordinal-group: 13;
            order: 12;
  }
}
@media screen and (min-width: 61.875rem) {
  .span1_xlarge {
    flex-basis: 8.33333333%;
    max-width: 8.33333333%;
  }
  .order1_xlarge {
    -webkit-box-ordinal-group: 2;
            order: 1;
  }
  .span2_xlarge {
    flex-basis: 16.66666667%;
    max-width: 16.66666667%;
  }
  .order2_xlarge {
    -webkit-box-ordinal-group: 3;
            order: 2;
  }
  .span3_xlarge {
    flex-basis: 25%;
    max-width: 25%;
  }
  .order3_xlarge {
    -webkit-box-ordinal-group: 4;
            order: 3;
  }
  .span4_xlarge {
    flex-basis: 33.33333333%;
    max-width: 33.33333333%;
  }
  .order4_xlarge {
    -webkit-box-ordinal-group: 5;
            order: 4;
  }
  .span5_xlarge {
    flex-basis: 41.66666667%;
    max-width: 41.66666667%;
  }
  .order5_xlarge {
    -webkit-box-ordinal-group: 6;
            order: 5;
  }
  .span6_xlarge {
    flex-basis: 50%;
    max-width: 50%;
  }
  .order6_xlarge {
    -webkit-box-ordinal-group: 7;
            order: 6;
  }
  .span7_xlarge {
    flex-basis: 58.33333333%;
    max-width: 58.33333333%;
  }
  .order7_xlarge {
    -webkit-box-ordinal-group: 8;
            order: 7;
  }
  .span8_xlarge {
    flex-basis: 66.66666667%;
    max-width: 66.66666667%;
  }
  .order8_xlarge {
    -webkit-box-ordinal-group: 9;
            order: 8;
  }
  .span9_xlarge {
    flex-basis: 75%;
    max-width: 75%;
  }
  .order9_xlarge {
    -webkit-box-ordinal-group: 10;
            order: 9;
  }
  .span10_xlarge {
    flex-basis: 83.33333333%;
    max-width: 83.33333333%;
  }
  .order10_xlarge {
    -webkit-box-ordinal-group: 11;
            order: 10;
  }
  .span11_xlarge {
    flex-basis: 91.66666667%;
    max-width: 91.66666667%;
  }
  .order11_xlarge {
    -webkit-box-ordinal-group: 12;
            order: 11;
  }
  .span12_xlarge {
    flex-basis: 100%;
    max-width: 100%;
  }
  .order12_xlarge {
    -webkit-box-ordinal-group: 13;
            order: 12;
  }
}
@media screen and (min-width: 75rem) {
  .span1_xxlarge {
    flex-basis: 8.33333333%;
    max-width: 8.33333333%;
  }
  .order1_xxlarge {
    -webkit-box-ordinal-group: 2;
            order: 1;
  }
  .span2_xxlarge {
    flex-basis: 16.66666667%;
    max-width: 16.66666667%;
  }
  .order2_xxlarge {
    -webkit-box-ordinal-group: 3;
            order: 2;
  }
  .span3_xxlarge {
    flex-basis: 25%;
    max-width: 25%;
  }
  .order3_xxlarge {
    -webkit-box-ordinal-group: 4;
            order: 3;
  }
  .span4_xxlarge {
    flex-basis: 33.33333333%;
    max-width: 33.33333333%;
  }
  .order4_xxlarge {
    -webkit-box-ordinal-group: 5;
            order: 4;
  }
  .span5_xxlarge {
    flex-basis: 41.66666667%;
    max-width: 41.66666667%;
  }
  .order5_xxlarge {
    -webkit-box-ordinal-group: 6;
            order: 5;
  }
  .span6_xxlarge {
    flex-basis: 50%;
    max-width: 50%;
  }
  .order6_xxlarge {
    -webkit-box-ordinal-group: 7;
            order: 6;
  }
  .span7_xxlarge {
    flex-basis: 58.33333333%;
    max-width: 58.33333333%;
  }
  .order7_xxlarge {
    -webkit-box-ordinal-group: 8;
            order: 7;
  }
  .span8_xxlarge {
    flex-basis: 66.66666667%;
    max-width: 66.66666667%;
  }
  .order8_xxlarge {
    -webkit-box-ordinal-group: 9;
            order: 8;
  }
  .span9_xxlarge {
    flex-basis: 75%;
    max-width: 75%;
  }
  .order9_xxlarge {
    -webkit-box-ordinal-group: 10;
            order: 9;
  }
  .span10_xxlarge {
    flex-basis: 83.33333333%;
    max-width: 83.33333333%;
  }
  .order10_xxlarge {
    -webkit-box-ordinal-group: 11;
            order: 10;
  }
  .span11_xxlarge {
    flex-basis: 91.66666667%;
    max-width: 91.66666667%;
  }
  .order11_xxlarge {
    -webkit-box-ordinal-group: 12;
            order: 11;
  }
  .span12_xxlarge {
    flex-basis: 100%;
    max-width: 100%;
  }
  .order12_xxlarge {
    -webkit-box-ordinal-group: 13;
            order: 12;
  }
}
@media screen and (min-width: 93.75rem) {
  .span1_xxxlarge {
    flex-basis: 8.33333333%;
    max-width: 8.33333333%;
  }
  .order1_xxxlarge {
    -webkit-box-ordinal-group: 2;
            order: 1;
  }
  .span2_xxxlarge {
    flex-basis: 16.66666667%;
    max-width: 16.66666667%;
  }
  .order2_xxxlarge {
    -webkit-box-ordinal-group: 3;
            order: 2;
  }
  .span3_xxxlarge {
    flex-basis: 25%;
    max-width: 25%;
  }
  .order3_xxxlarge {
    -webkit-box-ordinal-group: 4;
            order: 3;
  }
  .span4_xxxlarge {
    flex-basis: 33.33333333%;
    max-width: 33.33333333%;
  }
  .order4_xxxlarge {
    -webkit-box-ordinal-group: 5;
            order: 4;
  }
  .span5_xxxlarge {
    flex-basis: 41.66666667%;
    max-width: 41.66666667%;
  }
  .order5_xxxlarge {
    -webkit-box-ordinal-group: 6;
            order: 5;
  }
  .span6_xxxlarge {
    flex-basis: 50%;
    max-width: 50%;
  }
  .order6_xxxlarge {
    -webkit-box-ordinal-group: 7;
            order: 6;
  }
  .span7_xxxlarge {
    flex-basis: 58.33333333%;
    max-width: 58.33333333%;
  }
  .order7_xxxlarge {
    -webkit-box-ordinal-group: 8;
            order: 7;
  }
  .span8_xxxlarge {
    flex-basis: 66.66666667%;
    max-width: 66.66666667%;
  }
  .order8_xxxlarge {
    -webkit-box-ordinal-group: 9;
            order: 8;
  }
  .span9_xxxlarge {
    flex-basis: 75%;
    max-width: 75%;
  }
  .order9_xxxlarge {
    -webkit-box-ordinal-group: 10;
            order: 9;
  }
  .span10_xxxlarge {
    flex-basis: 83.33333333%;
    max-width: 83.33333333%;
  }
  .order10_xxxlarge {
    -webkit-box-ordinal-group: 11;
            order: 10;
  }
  .span11_xxxlarge {
    flex-basis: 91.66666667%;
    max-width: 91.66666667%;
  }
  .order11_xxxlarge {
    -webkit-box-ordinal-group: 12;
            order: 11;
  }
  .span12_xxxlarge {
    flex-basis: 100%;
    max-width: 100%;
  }
  .order12_xxxlarge {
    -webkit-box-ordinal-group: 13;
            order: 12;
  }
}
@media screen and (min-width: 47.5rem) {
  header .hide_on_small {
    display: -webkit-box;
    display: flex;
  }
}
@media screen and (min-width: 75rem) {
  header .header_before .button.separator {
    padding-left: 0.9em;
  }
}
@media screen and (min-width: 75rem) {
  header .header_before .button:last-child {
    padding-left: 0.9em;
  }
}
@media screen and (min-width: 61.875rem) {
  header .header_before .social_media_links .facebook {
    display: -webkit-box;
    display: flex;
  }
}
@media screen and (min-width: 30rem) {
  header .header_main {
    padding: 2.7em 0;
  }
}
@media screen and (min-width: 17.5rem) {
  header .header_main.scrolled_down .company_logo {
    max-height: calc(2.8125rem + 1.5625
                            * (100vw - 17.5rem)
                            / (93.75 - 17.5));
  }
}
@media screen and (min-width: 93.75rem) {
  header .header_main.scrolled_down .company_logo {
    max-height: 4.375rem;
  }
}
@media screen and (min-width: 61.875rem) {
  header .some_mitglied_wrapper .mitglied_sdw {
    margin-right: 1.25rem;
  }
}
@media screen and (min-width: 75rem) {
  header .some_mitglied_wrapper .mitglied_sdw {
    margin-right: 5rem;
  }
}
@media screen and (min-width: 17.5rem) {
  header .some_mitglied_wrapper .mitglied_sdw img {
    max-width: calc(5rem + 0
                            * (100vw - 17.5rem)
                            / (93.75 - 17.5));
  }
}
@media screen and (min-width: 93.75rem) {
  header .some_mitglied_wrapper .mitglied_sdw img {
    max-width: 5rem;
  }
}
@media screen and (min-width: 30rem) {
  .properties_map_wrapper .map .gm-style iframe ~ div {
    -webkit-transform: translateY(-7.5rem) !important;
            transform: translateY(-7.5rem) !important;
  }
}
@media screen and (min-width: 35rem) {
  .properties_map_wrapper .map {
    height: 34.375rem;
  }
}
@media screen and (min-width: 47.5rem) {
  .properties_map_wrapper .map {
    height: 40.625rem;
  }
}
@media screen and (min-width: 30rem) {
  .property_details .contact_person_wrapper {
    top: -6.3em;
  }
}
@media screen and (min-width: 61.875rem) {
  .property_details .navigation {
    -webkit-box-pack: end;
            justify-content: flex-end;
  }
}
@media screen and (min-width: 47.5rem) {
  .find_properties .title_wrapper h2 {
    font-size: 137.5%;
  }
}
@media screen and (min-width: 61.875rem) {
  .find_properties .title_wrapper h2 {
    font-size: 125%;
  }
}
@media screen and (min-width: 75rem) {
  .find_properties .title_wrapper h2 {
    font-size: 143.75%;
  }
}
@media screen and (min-width: 75rem) {
  .find_properties .form .checkbox:first-child {
    margin-top: 0;
  }
}
@media screen and (min-width: 75rem) {
  .find_properties .form .checkbox_wrapper {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
  }
}
@media screen and (min-width: 75rem) {
  .find_properties .form .buttons {
    margin-bottom: 0;
    margin-top: 0;
  }
}
@media screen and (min-width: 47.5rem) {
  .find_properties .form [data-search-options="visible"] {
    margin-bottom: 1.2em;
    margin-top: 0;
  }
}
@media screen and (min-width: 61.875rem) {
  .find_properties .form [data-search-options="visible"] {
    margin-bottom: 0;
    margin-top: 0.9em;
  }
}
@media screen and (min-width: 30rem) {
  .search_agent {
    margin-top: -5.4em;
  }
}
@media screen and (min-width: 47.5rem) {
  .search_agent .search_agent_wrapper {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: justify;
            justify-content: space-between;
  }
}
@media screen and (min-width: 47.5rem) {
  .search_agent .search_agent_wrapper ol {
    display: -webkit-inline-box;
    display: inline-flex;
    margin: 0;
  }
}
@media screen and (min-width: 35rem) {
  .search_agent .search_agent_wrapper .button {
    width: auto;
  }
}
@media screen and (min-width: 61.875rem) {
  .search_agent .search_agent_wrapper .button {
    -webkit-box-flex: 0;
            flex: 0 1 auto;
  }
}
@media screen and (min-width: 30rem) {
  .dialog_search_agent form {
    margin-top: 0.9em;
  }
}
@media screen and (min-width: 47.5rem) {
  .dialog_search_agent form {
    margin: 0;
  }
}
@media screen and (min-width: 61.875rem) {
  .dialog_search_agent form {
    padding-right: 1.8em;
  }
}
@media screen and (min-width: 47.5rem) {
  .estate_object_picture {
    height: 37.5rem !important;
  }
}
@media screen and (min-width: 61.875rem) {
  .estate_object_picture {
    height: 50rem !important;
  }
}
@media screen and (min-width: 35rem) {
  .badges .link_apple {
    margin-left: 0.9em;
  }
}
@media screen and (min-width: 47.5rem) {
  .two_columns {
    -webkit-column-count: 2;
            column-count: 2;
    -webkit-column-gap: 3.6em;
            column-gap: 3.6em;
    margin-bottom: 3.6em;
  }
}
@media screen and (min-width: 61.875rem) {
  .testimonials_slider .slide {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
            flex-direction: row;
  }
}
@media screen and (min-width: 61.875rem) {
  .testimonials_slider .info {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
            flex-direction: row;
    margin-left: 2.7em;
    margin-right: 2.7em;
    margin-bottom: 0;
    min-width: 16.25rem;
  }
}
@media screen and (min-width: 47.5rem) {
  .stoerer {
    display: -webkit-box;
    display: flex;
  }
}
@media screen and (min-width: 61.875rem) {
  .stoerer {
    right: 0;
    height: 18.0625rem;
    width: 18.0625rem;
  }
}
@media screen and (min-width: 75rem) {
  .stoerer {
    right: 7.2em;
  }
}
@media screen and (min-width: 47.5rem) {
  .figure {
    margin-top: 0;
  }
}
@media screen and (min-width: 35rem) {
  .multibox p {
    text-align: left;
  }
}
@media screen and (min-width: 61.875rem) {
  .multibox .button_wrapper .button {
    margin: 0;
  }
}
@media screen and (min-width: 35rem) {
  .multibox .divider {
    border-right: 0.1875rem solid rgba(255, 255, 255, 0.6);
  }
}
@media screen and (min-width: 35rem) {
  .multibox .title {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
            flex-direction: row-reverse;
    -webkit-box-pack: end;
            justify-content: flex-end;
  }
}
@media screen and (min-width: 35rem) {
  .multibox .title h3 {
    text-align: left;
  }
}
@media screen and (min-width: 35rem) {
  .multibox .title .icon {
    margin: 0 0.9em 0 0;
  }
}
@media screen and (min-width: 30rem) {
  .multibox {
    top: -4.5em;
  }
}
@media screen and (min-width: 30rem) {
  .projects_cards_overview .description {
    width: 80%;
  }
}
@media screen and (min-width: 35rem) {
  .projects_cards_overview .description {
    width: 60%;
  }
}
@media screen and (min-width: 35rem) {
  .service_slider .pagination_wrapper {
    display: -webkit-box;
    display: flex;
  }
}
@media screen and (min-width: 35rem) {
  .service_slider .controlls .control_slides {
    background-color: rgba(32, 32, 32, 0.8);
    border-radius: 0.25rem;
    cursor: pointer;
    display: block;
    fill: #fff;
    height: 2.8125rem;
    margin-top: -7.2em;
    opacity: 0;
    padding: 0.5em;
    position: absolute;
    top: 55%;
    -webkit-transition: opacity 300ms;
    transition: opacity 300ms;
    width: 2.8125rem;
    z-index: 999;
  }
  .service_slider .controlls .control_slides:focus,
  .service_slider .controlls .control_slides:hover {
    background-color: rgba(226, 0, 42, 0.8);
    outline: 0;
  }
}
@media screen and (min-width: 35rem) {
  .startpage_cta .cta_text h3 {
    font-size: 2.375rem;
  }
}
@media screen and (min-width: 30rem) {
  .company_app .smartphone .figure {
    width: 50%;
  }
}
@media screen and (min-width: 47.5rem) {
  .company_app .smartphone .figure {
    width: 80%;
  }
}
@media screen and (min-width: 61.875rem) {
  .current_objects .last {
    display: -webkit-box;
    display: flex;
  }
}
@media screen and (min-width: 30rem) {
  .gototop {
    right: 3rem;
  }
}
@media screen and (min-width: 47.5rem) {
  .image_slider .slide {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
            flex-direction: row;
  }
}
@media screen and (min-width: 47.5rem) {
  .image_slider .slide {
    height: 12.5rem;
  }
}
@media screen and (min-width: 75rem) {
  .image_slider .slide {
    height: 18.75rem;
  }
}
@media screen and (min-width: 93.75rem) {
  .image_slider .slide {
    height: 25rem;
  }
}
@media screen and (min-width: 47.5rem) {
  .image_slider .slide .image {
    height: 12.5rem;
  }
}
@media screen and (min-width: 75rem) {
  .image_slider .slide .image {
    height: 18.75rem;
  }
}
@media screen and (min-width: 93.75rem) {
  .image_slider .slide .image {
    height: 25rem;
  }
}
@media screen and (min-width: 30rem) {
  .current_projects_headline {
    text-align: center;
  }
}
@media screen and (min-width: 61.875rem) {
  .current_project .entry {
    margin: 1.8em 0 0 1.8em;
  }
}
@media screen and (min-width: 17.5rem) {
  .current_project .entry .title {
    font-size: calc(1.1875rem + -0.0625
                            * (100vw - 17.5rem)
                            / (93.75 - 17.5));
  }
}
@media screen and (min-width: 93.75rem) {
  .current_project .entry .title {
    font-size: 1.125rem;
  }
}
@media screen and (max-width: 74.9375rem) {
  .current_project .entry .info .photo {
    display: none;
  }
}
@media screen and (min-width: 61.875rem) {
  .red_main {
    margin-top: -14.4em;
  }
}
@media screen and (min-width: 75rem) {
  .red_main {
    margin-top: -21.6em !important;
  }
}
@media screen and (max-width: 61.8125rem) {
  .red_main {
    padding-top: 14.4em;
  }
}
@media screen and (min-width: 47.5rem) {
  .red_main p {
    margin-bottom: 7.2em;
    padding-right: 3.6em;
  }
}
@media screen and (min-width: 17.5rem) {
  .red_main h3 {
    font-size: calc(1rem + 1
                            * (100vw - 17.5rem)
                            / (93.75 - 17.5));
  }
}
@media screen and (min-width: 93.75rem) {
  .red_main h3 {
    font-size: 2rem;
  }
}
@media screen and (min-width: 61.875rem) {
  .arev_app {
    padding: 23.4em 0 9em;
  }
}
@media screen and (max-width: 61.8125rem) {
  .arev_app .smartphone {
    margin-bottom: -14.4em;
    z-index: 0 !important;
  }
  .arev_app .smartphone .figure {
    z-index: -1 !important;
  }
}
@media screen and (min-width: 47.5rem) {
  .download_section p {
    padding: 0 9em;
  }
}
@media screen and (min-width: 75rem) {
  .download_section p {
    padding: 0 21.6em;
  }
}
@media screen and (min-width: 17.5rem) {
  .download_section h2 {
    font-size: calc(1.125rem + 0.875
                            * (100vw - 17.5rem)
                            / (93.75 - 17.5));
  }
}
@media screen and (min-width: 93.75rem) {
  .download_section h2 {
    font-size: 2rem;
  }
}
@media screen and (max-width: 47.4375rem) {
  .download_section .figure_overview .figure:first-child,
  .download_section .figure_overview .figure:last-child {
    display: none !important;
  }
}
@media screen and (min-width: 61.875rem) {
  .main_menu {
    display: block;
  }
}
@media screen and (min-width: 35rem) {
  .newsletter_quick_subscribe form {
    display: -webkit-box;
    display: flex;
  }
}
@media screen and (min-width: 61.875rem) {
  .toggle_offcanvas_menu {
    display: none;
  }
}
@media screen and (min-width: 30rem) {
  .toggle_offcanvas_menu label .text {
    display: block;
  }
}
@media screen and (max-width: 61.8125rem) {
  .offcanvas_menu_state:checked ~ .offcanvas_menu_wrapper .offcanvas_menu,
  .offcanvas_menu_state:target ~ .offcanvas_menu_wrapper .offcanvas_menu {
    opacity: 1;
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
    visibility: visible;
  }
  .offcanvas_menu_state:checked ~ .offcanvas_menu_wrapper .offcanvas_menu_overlay,
  .offcanvas_menu_state:target ~ .offcanvas_menu_wrapper .offcanvas_menu_overlay {
    opacity: 1;
    visibility: visible;
  }
  .offcanvas_menu_state:checked ~ header .toggle_offcanvas_menu label .icon,
  .offcanvas_menu_state:target ~ header .toggle_offcanvas_menu label .icon {
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
    -webkit-transition: -webkit-transform 300ms;
    transition: -webkit-transform 300ms;
    transition: transform 300ms;
    transition: transform 300ms, -webkit-transform 300ms;
  }
  .offcanvas_menu_state:checked ~ header .toggle_offcanvas_menu .open_offcanvas_menu,
  .offcanvas_menu_state:target ~ header .toggle_offcanvas_menu .open_offcanvas_menu {
    display: none;
  }
  .offcanvas_menu_state:checked ~ header .toggle_offcanvas_menu .close_offcanvas_menu,
  .offcanvas_menu_state:target ~ header .toggle_offcanvas_menu .close_offcanvas_menu {
    display: block;
  }
}
@media screen and (min-width: 30rem) {
  .offcanvas_menu {
    width: 21.875rem;
  }
}
@media screen and (min-width: 47.5rem) {
  .open_street_map .map {
    height: 34.375rem;
  }
}
@media screen and (min-width: 47.5rem) {
  .open_street_map .plan_route {
    -webkit-box-align: end;
            align-items: flex-end;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
            flex-direction: row;
  }
}
@media screen and (min-width: 47.5rem) {
  .open_street_map .plan_route .saddr {
    margin: 0 1.8em 0 0;
    max-width: 40.625rem;
  }
  .open_street_map .plan_route .saddr input {
    margin-bottom: 0;
  }
}
@media screen and (min-width: 47.5rem) {
  .open_street_map .plan_route .select_wrapper {
    margin: 0 1.8em 0 0;
  }
}
@media screen and (min-width: 47.5rem) {
  .open_street_map .plan_route .select_wrapper .select {
    margin-bottom: 0;
  }
}
@media screen and (min-width: 47.5rem) {
  .open_street_map .plan_route .button_wrapper button {
    margin-bottom: 0;
  }
}
@media screen and (min-width: 30rem) {
  .social_media_share a.print {
    display: block;
  }
}
@media screen and (min-width: 30rem) {
  .steuernews_article_slider .wrapper_inner {
    width: 25rem;
  }
}
@media screen and (min-width: 35rem) {
  .steuernews_article_slider .wrapper_inner {
    width: 31.25rem;
  }
}
@media screen and (min-width: 47.5rem) {
  .steuernews_article_slider .wrapper_inner {
    width: 37.5rem;
  }
}
@media screen and (min-width: 30rem) {
  .steuernews_article_slider .slide .description {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
    width: 80%;
  }
}
@media screen and (min-width: 35rem) {
  .steuernews_article_slider .slide .description {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
    width: 60%;
  }
}
@media screen and (min-width: 35rem) {
  .steuernews_article_slider .slide .description .links {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
            flex-direction: row;
  }
  .steuernews_article_slider .slide .description .links .link {
    margin-bottom: 0;
  }
}
@media screen and (min-width: 30rem) {
  .steuernews_article_slider .control_slides {
    height: 3rem;
    width: 3rem;
    margin-top: -5.4em;
  }
}
@media screen and (min-width: 35rem) {
  .steuernews_article_slider .control_slides {
    margin-top: -6.3em;
  }
}
@media screen and (min-width: 47.5rem) {
  .steuernews_article_slider .control_slides {
    margin-top: -7.2em;
  }
}
@media screen and (min-width: 30rem) {
  .steuernews_article_slider .prev_slide {
    right: 5.625rem;
  }
}
@media screen and (min-width: 30rem) {
  .steuernews_preview .description {
    width: 80%;
  }
}
@media screen and (min-width: 35rem) {
  .steuernews_preview .description {
    width: 60%;
  }
}
@media screen and (min-width: 61.875rem) {
  .news_image {
    margin-left: 1.8em;
  }
}
@media screen and (min-width: 47.5rem) {
  .steuernews_sidebar {
    margin-top: 0;
  }
}
@media screen and (min-width: 17.5rem) {
  .team_group_overview .overview_title {
    font-size: calc(1.1875rem + -0.1875
                            * (100vw - 17.5rem)
                            / (93.75 - 17.5));
  }
}
@media screen and (min-width: 93.75rem) {
  .team_group_overview .overview_title {
    font-size: 1rem;
  }
}
@media screen and (min-width: 17.5rem) {
  .team_title h3 {
    font-size: calc(1.25rem + -0.125
                            * (100vw - 17.5rem)
                            / (93.75 - 17.5));
  }
}
@media screen and (min-width: 93.75rem) {
  .team_title h3 {
    font-size: 1.125rem;
  }
}
@media screen and (min-width: 17.5rem) {
  .team_section_title .entry .title {
    font-size: calc(1.1875rem + -0.1875
                            * (100vw - 17.5rem)
                            / (93.75 - 17.5));
  }
}
@media screen and (min-width: 93.75rem) {
  .team_section_title .entry .title {
    font-size: 1rem;
  }
}
@media screen and (min-width: 47.5rem) {
  .team_overview .entry {
    margin-top: 0;
  }
}
@media screen and (min-width: 93.75rem) {
  .team_overview .entry {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
            flex-direction: row;
  }
}
@media screen and (min-width: 47.5rem) {
  .team_overview .photo {
    display: block;
    left: auto;
    right: -3.6em;
    top: auto;
  }
}
@media screen and (min-width: 47.5rem) {
  .team_overview .photo img {
    margin: 0;
  }
}
@media screen and (max-width: 34.9375rem) {
  .team_overview .info .contact {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    margin-bottom: -1.2em;
  }
}
@media screen and (min-width: 61.875rem) {
  .team_overview .info .buttons .button {
    margin-top: 1.8em;
  }
}
@media screen and (min-width: 61.875rem) {
  .team_overview .info .buttons .call {
    display: none;
  }
}
@media print {
  .company_logo {
    display: block;
    margin-bottom: 0.9em;
    max-width: 7.5rem !important;
  }
  .badges {
    display: none;
  }
  .main_section {
    text-align: left !important;
  }
  .multibox .title .icon {
    display: none !important;
  }
  .google_maps h2 {
    display: none;
    margin: 0;
  }
  .contact_overview_map {
    padding-top: 0 !important;
  }
  .reference_overview .reference_entry {
    margin-bottom: 1.8em !important;
  }
  .reference_overview .reference_entry .description .short_description .name {
    font-weight: 700;
    text-transform: uppercase;
  }
  .request_property {
    display: none;
  }
  .hideme_print,
  .cms_live_management {
    display: none !important;
  }
  .showme_print {
    display: inherit !important;
    height: auto !important;
    max-height: none !important;
  }
  * {
    background: transparent !important;
    border-color: black !important;
    color: black !important;
    text-shadow: none !important;
  }
  *:before {
    color: black !important;
  }
  @page {
    margin: 2cm;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-top: 0.9em;
    page-break-after: avoid;
  }
  .google_maps .distance::before {
    content: " - ";
    display: inline;
  }
  main a {
    text-decoration: underline;
  }
  main a.touch_link {
    margin-bottom: 1.8em;
    page-break-inside: avoid;
  }
  .table {
    overflow-y: inherit;
  }
  tr {
    page-break-inside: avoid;
  }
  dl {
    margin: 0;
  }
  dt {
    font-weight: 700;
    margin-bottom: 0.9em;
  }
  dd {
    margin: 0;
  }
  [role="tooltip"] {
    display: none;
  }
  abbr[title]:after {
    content: " (" attr(title) ")";
  }
  .search dl br,
  .search dl em,
  .search dl img {
    display: none;
  }
  .news_entity .hide_creation_date {
    display: none;
  }
  body {
    display: block;
    font-size: 85%;
    overflow-x: visible;
  }
  .page {
    display: block;
  }
  main {
    padding-top: 1.8em;
  }
  .company_logo {
    display: block;
    max-width: 18.75rem;
  }
  .company_logo img {
    display: block;
  }
  .large_photo img {
    max-height: 15rem !important;
    width: auto;
  }
  .property_details .row h1 {
    font-size: 140% !important;
    margin-top: 0rem !important;
  }
  .property_details .row .details_view {
    width: 5cm !important;
    margin-left: 1cm !important;
    float: right !important;
  }
  .property_details .row .details_view h2 {
    font-size: 120% !important;
  }
  .qr_code_print {
    border-top: 0.0625rem solid;
    margin: 1.8em 0 0;
    padding: 0.9em 0 0;
    page-break-inside: avoid;
  }
  .qr_code_print:after {
    clear: both;
  }
  .qr_code_print:after,
  .qr_code_print:before {
    content: "";
    display: table;
  }
  .qr_code_print img {
    border: 0.0625rem solid black;
    float: left;
  }
  .qr_code_print p {
    margin-left: 10rem;
  }
}
